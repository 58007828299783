import React, {useRef,useState, useEffect} from 'react'
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap'
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";

const UserAccountSetting =() =>{
    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const interests = [
        "Public Health", 
        "Food Safety", 
        "Zoonosis", 
        "Climate Change", 
        "Environmental Health", 
        "Planetary Health", 
        "Sustainable Development", 
        "Vector Bone Diseases",
        "Neglected Tropical Diseases",
        "Antimicrobial Resistance",
        "Multidisciplinary One Health Action Research",
        // "other",
    ]

    const [currentUser, setCurrentUser] = useState();
    // const userData = JSON.parse(Cookies.get("user"));

    const hiddenFileInput = useRef(null);
    const [loading, setLoading] = useState(false);
    const [personalLoading, setPersonalLoading] = useState(false);
    const [educationLoading, setEducationLoading] = useState(false);
    const [specialityLoading, setSpecialityLoading] = useState(false);

    const [educationForm, setEducationForm] = useState(true);
    const [specialityForm, setSpecialityForm] = useState(false);
    const [postImage, setPostImage] = useState();
    const [personalMedia, setPersonalMedia] = useState();
    const [trigger, setTrigger] = useState(false);

    const [formData, setFormData] = useState({
        content: "",
        description: "desc",
        user_image: null
      });

      const [personalData, setPersonalData] = useState({
        name:"",
        firstname:"",
        lastname:"",
        bio:"",
        email:"",
        interests:[]
      });

      const handleInterestsCheck = (e, interest) => {
    const isChecked = e.target.checked;

    if (isChecked) {
        setPersonalData((prevData) => ({
            ...prevData,
            interests: [...prevData.interests, interest]
        }));
    } else {
        setPersonalData((prevData) => ({
            ...prevData,
            interests: prevData.interests.filter((item) => item !== interest)
        }));
    }
};

    const handleClick = event => {
        hiddenFileInput.current.click();
      };

       function handleMediaChange(e) {
        console.log(e.target.files);
        setPostImage(URL.createObjectURL(e.target.files[0]));
        setPersonalMedia(e.target.files[0]);
        
    }

    const handlePersonalInputChange = (e) => {
        setPersonalData(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
        
        // userData.user[e.target.name] = e.target.value 
       
      }


    const personalUpdate = async (e, name, email) => {
        e.preventDefault()
        setPersonalLoading(true)

        var personalPostData = new FormData()
        personalPostData.append("user_image", personalMedia)
        personalPostData.append("name", personalData.name !== "" ? personalData.name : name.toString())
        personalPostData.append("firstname",personalData.firstname)
        personalPostData.append("lastname",personalData.lastname)
        personalPostData.append("email",personalData.email !== "" ? personalData.email :email.toString())
        personalPostData.append("bio",personalData.bio)
        personalPostData.append("interests", personalData.interests)
        // for (let pair of personalPostData.entries()) {
        //     console.log(pair[0] + ": " + pair[1]);
        //   }
        try {
           const res = await fetch("https://api.chulofilmhouse.co.ke/api/users", {
             method: 'POST',
             headers: { 
                // "Content-Type": "multipart/form-data",
              "Accept": "application/json" ,
               "Authorization":`Bearer ${token}`},
             body: personalPostData
           });
           const user = await res.json()
       
            // If no error and we have user data, return it
        if (res.ok && user) {
            setTrigger(prevTrigger => !prevTrigger);
           console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
        //    console.log(JSON.stringify(user));
        //    console.log( JSON.stringify(userData));
           console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
        //    Cookies.set('user', JSON.stringify(userData))
//   Cookies.set('user', JSON.stringify(user.data))
//   console.log(user.data);
//   console.log(Cookies.get("user"));
  setLoading(false)
  console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
          return user
        }else{
           console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHH");
  console.log(user.message);
   
//   setErrMessage(user.message)
  setLoading(false)
  console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHH"); 
        }
     } catch (error) {
        console.log("GGGGGGGGGGGGGGGGGGGGGGGGG");
  console.log(error);
//   setErrMessage(user.message)
  setLoading(false)
  console.log("GGGGGGGGGGGGGGGGGGGGGGGGG");   
     }
        
        // Return null if user data could not be retrieved
        return null
      };

      useEffect( () => {
        fetch(`https://api.chulofilmhouse.co.ke/api/users/${user.id}`,
       {headers: { "Content-Type": "application/json" ,
        "Accept":"application/json", 
       "Authorization":`Bearer ${token}`}})
         .then(response => response.json())
         .then(json =>{
           console.log("FFFFFFFFFFFFFF");
           console.log(json);
           console.log("FFFFFFFFFFFFFF");
           setCurrentUser(json)
        //    setPersonalData( ({
        //     ["interests"]: json["interests"] 
        //   }))
           // return json
           })
         .catch(error => console.error(error));
         
   }, [token, user.id, trigger]);

  return(
     <>
     {
               !currentUser ?
                  <div>...</div>
               :
               Object.entries([currentUser]).map((curruser, index) => (
                <div key={index}>
        <Container>
        <Row>
            <Col lg="6">
                <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Personal Details</h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="acc-edit">
                        <Form>
                        <input type="file" 
                                        ref={hiddenFileInput}
                                        accept="image/*"
                                        style={{ display: 'none' }} 
                                        onChange={handleMediaChange} />
                        <Link to="#" className="btn btn-soft-primary" onClick={handleClick}>
                        <img src={!postImage ? `https://api.chulofilmhouse.co.ke/${curruser["1"].profile_picture}` : postImage} alt="user1" className="avatar-60 rounded-circle img-fluid"/>
                                            </Link>
                        
                        
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="uname" className="form-label">Full Name:</Form.Label>
                                <Form.Control type="text" className="form-control" id="uname" name="name" onChange={handlePersonalInputChange} value={personalData.name === "" ? curruser["1"].name : personalData.name } placeholder="Name"/>
                            </Form.Group>
                            <Row>
                                <Col md="6">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="firstname" className="form-label">First Name:</Form.Label>
                                <Form.Control type="text" className="form-control" id="firstname" name="firstname" onChange={handlePersonalInputChange} value={personalData.firstname === "" ? curruser["1"].firstname : personalData.firstname } placeholder="First Name"/>
                            </Form.Group>
                            </Col>
                                <Col md="6">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="lastname" className="form-label">Last Name:</Form.Label>
                                <Form.Control type="text" className="form-control" id="lastname" name="lastname" onChange={handlePersonalInputChange} value={personalData.lastname === "" ? curruser["1"].lastname : personalData.lastname } placeholder="Last Name"/>
                            </Form.Group>
                            </Col>
                            </Row>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="altemail" className="form-label">Email:</Form.Label>
                                <Form.Control type="email" className="form-control" id="altemail" name="email" onChange={handlePersonalInputChange} value={personalData.email === "" ? curruser["1"].email : personalData.email } placeholder="Email"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="bio" className="form-label">Bio:</Form.Label>
                                <Form.Control as="textarea" rows={3} className="form-control" id="bio" name="bio" onChange={handlePersonalInputChange} defaultValue={personalData.bio === "" ? curruser["1"].bio : personalData.bio } placeholder="Bio"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label className="d-block form-label">Interests:</Form.Label>
                                {!interests ? <></> : Object.entries(interests).map((interest, index) => (
                                <Form.Check key={index} className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" 
                                    // id="public-health"
                                    checked={personalData.interests.includes(interest["1"])}
                                    onChange={(e) => handleInterestsCheck(e, interest["1"])}
                                    />
                                    <Form.Check.Label className="form-check-label" htmlFor="public_health">{interest["1"]}</Form.Check.Label>
                                </Form.Check>
                                )
                                )}
                                {/* <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" 
                                    id="food-safety"
                                    checked={personalData.interests.includes('Food Safety')}
                                    onChange={(e) => handleInterestsCheck(e, 'Food Safety')}
                                    />
                                    <Form.Check.Label className="form-check-label" htmlFor="food_safety">Food Safety</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" 
                                    id="public-health"
                                    checked={personalData.interests.includes('Synopsis')}
                                    onChange={(e) => handleInterestsCheck(e, 'Synopsis')}
                                    />
                                    <Form.Check.Label className="form-check-label" htmlFor="hindi">Synopsis</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="spanish" defaultChecked/>
                                    <Form.Check.Label className="form-check-label" htmlFor="spanish">Environmental Health</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="arabic"/>
                                    <Form.Check.Label className="form-check-label" htmlFor="arabic">Vector Bone Diseases</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="italian"/>
                                    <Form.Check.Label className="form-check-label" htmlFor="italian">Neglected Tropical Diseases</Form.Check.Label>
                                </Form.Check> */}
                            </Form.Group>
                            <Button type="button" className="btn btn-primary me-2" onClick={ (e)=> personalUpdate(e,curruser["1"].name, curruser["1"].email)}>Update</Button>
                            <Button type="reset" className="btn bg-soft-danger  text-black">Cancel</Button>
                        </Form>
                    </div>
                </Card.Body>
                </Card>
            </Col>
            <Col lg="6">
                <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        {/* <h4 className="card-title">Speciality</h4> */}
                        <Button id="chat-start" onClick={()=>{
                            setEducationForm(true)
                            setSpecialityForm(false)
                        }} bsPrefix={educationForm ? "btn bg-primary font-bold mt-1 me-2" :"btn bg-secondary text-white mt-1 me-2"}>Education</Button>
                        <Button id="chat-start" onClick={()=>{
                            setEducationForm(false)
                            setSpecialityForm(true)
                        }}  bsPrefix={specialityForm ? "btn bg-primary font-bold mt-1 me-2" :"btn bg-secondary text-white mt-1 me-2"}>Speciality</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="acc-edit">
                        {educationForm ?
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="facebook" className="form-label">Institution:</Form.Label>
                                <Form.Control type="text" className="form-control" id="facebook" defaultValue={curruser["1"].institution} placeholder={curruser["1"].type !== "Student" && curruser["1"].type !== "Organisation" ? "Institution attended" : "Current institution"}/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="twitter" className="form-label">School:</Form.Label>
                            <Form.Control type="text" className="form-control" id="twitter" defaultValue={curruser["1"].school} placeholder="School"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="google" className="form-label">Faculty:</Form.Label>
                            <Form.Control type="text" className="form-control" id="google" defaultValue={curruser["1"].faculty} placeholder="Faculty"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="instagram" className="form-label">Program/Course:</Form.Label>
                            <Form.Control type="text" className="form-control" id="instagram" defaultValue={curruser["1"].course} placeholder="Course"/>
                            </Form.Group>
                            {curruser["1"].type !== "Student" ? 
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="instagram" className="form-label">Year of Graduation:</Form.Label>
                            <Form.Control type="text" className="form-control" id="instagram" defaultValue={curruser["1"].grad_year} placeholder="Graduation Year"/>
                            </Form.Group> : <></>}
                            
                            <Button type="button" className="btn btn-primary me-2">Update</Button>
                            <Button type="reset" className="btn bg-soft-danger text-black">Cancel</Button>
                        </Form> : <></>}
                        {curruser["1"].type !== "Student" && specialityForm ?
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="facebook" className="form-label">Speciality:</Form.Label>
                                <Form.Control type="text" className="form-control" id="facebook" defaultValue={curruser["1"].speciality} placeholder="Speciality"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="twitter" className="form-label">Organisation:</Form.Label>
                            <Form.Control type="text" className="form-control" id="twitter" defaultValue={curruser["1"].organisation} placeholder="Organisation"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="google" className="form-label">Role:</Form.Label>
                            <Form.Control type="text" className="form-control" id="google" defaultValue={curruser["1"].title} placeholder="Role/Title"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="instagram" className="form-label">Location:</Form.Label>
                            <Form.Control type="text" className="form-control" id="instagram" defaultValue={curruser["1"].location} placeholder="Location"/>
                            </Form.Group>
                            <Button type="button" className="btn btn-primary me-2">Update</Button>
                            <Button type="reset" className="btn bg-soft-danger text-black">Cancel</Button>
                        </Form> : <></>}
                    </div>
                </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
    </div>
               ))}
     </>
  )

}

export default UserAccountSetting;