import React, {useState, useRef, useEffect} from 'react'
import {Row,Col,Container,Dropdown,Tooltip,OverlayTrigger, Modal, Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
// import FadeInOut from './FadeInOut'

import FileDownloadButton from './fileDownload'

// img
import pageimg1 from '../../../assets/images/page-img/43.png'
import pageimg2 from '../../../assets/images/page-img/44.png'
import pageimg3 from '../../../assets/images/page-img/45.png'
import pageimg4 from '../../../assets/images/page-img/46.png'
import pageimg5 from '../../../assets/images/page-img/47.png'

import img1 from '../../../assets/images/page-img/47.png'

import axios from 'axios';

const MassEmail = () => {
    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const hiddenFileInput = useRef(null);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState();
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [errMessage, setErrMessage] = useState("");
    const [trigger, setTrigger] = useState(false);

    const [formData, setFormData] = useState({
        from: "",
        subject: "",
        message: "",
      });

      useEffect(() => {
        const token = JSON.parse(Cookies.get("user")).token
    axios.get('https://api.chulofilmhouse.co.ke/api/users', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          const json = response.data;
          setUsers(json);
        })
        .catch(error => console.error(error));
    }, [trigger]);

     
       // Function to handle individual row checkbox change
  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((e) => e !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

 // Function to handle "Select All" checkbox change
      const handleSelectAllChange = (event) => {
        console.log(selectedEmails);
        if (event.target.checked) {
          setSelectedEmails(users.map((item) => item.email));
        } else {
          setSelectedEmails([]);
        }
      };

      const handleChange = (e) => {
        setFormData(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
      }

      const validateForm = (formValues) => {
        if ( !formValues.from ||!formValues.subject ||!formValues.message ||!users ) {
          setErrMessage('fields cannot be empty');
          return false;
        }
        return true;
      }

      const sendEmails = async () => {
        // setLoading(true)
        const toField = selectedEmails.join(',');
       var emailData = new FormData()
       emailData.append("address", formData.from)
       emailData.append("subject", formData.subject)
       emailData.append("message", formData.message)
       emailData.append("to", toField)
    //    emailData.append("to", selectedEmails)
        try {
      
          const response = await fetch('https://api.chulofilmhouse.co.ke/api/sendemail', {
            method: 'POST',
            headers: {
            //   'Content-Type': 'multipart/form-data',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: emailData,
          });
      
          if (response.ok) {
            // Handle successful post
            const resData = await response.json();
            console.log(resData);
            setFormData({
              
            });
            setLoading(false)
            setShow(false)
            setTrigger(prevTrigger => !prevTrigger);
          } else {
            const errorData = await response.json();
            console.error('Post failure:', errorData);
            setErrMessage(errorData.message);
            // Handle post failure, such as showing an error message to the user
          }
        } catch (error) {
          console.error('Posting failed:', error);
          setErrMessage(error.message);
        //   setErrMessage(error.message);
          // Handle post failure, such as showing an error message to the user
        }
        
      };

      const submitEmail = (e) =>{
        e.preventDefault();
        setLoading(true)
    
        //= Validate Form
        if (!validateForm(formData)) return;
        // setLoading(true)
        //= Clear Error Message
        setErrMessage('');
    
        sendEmails();
      }
   return(
       <>
           <div id='content-page' className='content-page'>           
            <Container>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Files</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                { user.role === "SuperAdmin" ?  
                                                <Link to="#" onClick={handleShow} className="chat-icon-delete btn bg-primary text-white">
                                                <i onClick={handleShow} className="material-symbols-outlined">
                                                                    email
                                                                </i>Send Email
                                                </Link>: <></>
}
                                            </div>
                                {/* <div className="card-header-toolbar d-flex align-items-center">
                                    <Dropdown>
                                        <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                            more_horiz
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                            <Dropdown.Item href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                                
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    {/* <Row className="justify-content-between d-flex">
                                        <Col sm="12" md="6">
                                            <div id="user_list_datatable_info" className="dataTables_filter">
                                                <form className="me-3 position-relative">
                                                    <div className="form-group mb-0">
                                                        <input type="search" className="form-control" id="exampleInputSearch" placeholder="Search"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className="user-list-files d-flex justify-content-end">
                                                <Link to="#" className="chat-icon-phone btn bg-soft-primary">
                                                Print
                                                </Link>
                                                <Link to="#" className="chat-icon-video btn bg-soft-primary">
                                                Excel
                                                </Link>
                                                <Link to="#" className="chat-icon-delete btn bg-soft-primary">
                                                Pdf
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row> */}
                                    
                                    <table className="files-lists table table-striped mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className=" text-center">
                                                        <input type="checkbox" className="form-check-input" onChange={handleSelectAllChange}/>
                                                    </div>
                                                </th>
                                                {/* <th scope="col"></th> */}
                                                <th scope="col"></th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                {/* <th scope="col">Description</th> */}
                                                {/* <th scope="col">File Category</th> */}
                                                <th scope="col">Type</th>
                                                <th scope="col">Action</th>
                                                {/* <th scope="col">Author</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
               !users ?
                  <div>...</div>
               :
               Object.entries(users).map((tab, index) =>{ 
                return (
                // <FadeInOut key={index} 
                // show={true}
                // >
                <tr>
                                                <td>
                                                    <div className=" text-center">
                                                        <input type="checkbox" checked={selectedEmails.includes(tab["1"].email)}
                    onChange={() => handleCheckboxChange(tab["1"].email)} className="form-check-input"/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <img className="rounded-circle img-fluid avatar-40 me-2" src={`${tab["1"].profile_picture}`} alt="" style={{objectFit: "cover"}}/>
                                                </td>
                                                <td>{tab["1"].name}</td>
                                                <td>
                                                {tab["1"].email}
                                                </td>
                                                <td>{tab["1"].type}</td>
                                                {/* <td>
                                                {tab["1"].profile_picture}
                                                </td> */}
                                             { user.role === "SuperAdmin" ?    <td>
                                                    <div className="flex align-items-center list-user-action">
                                                        
                                 

                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Suspend Account</Tooltip>}>
                                                            <Link to="#">
                                                                <i className="material-symbols-outlined me-3">
                                                                <span class="material-symbols-outlined">
account_circle_off
</span>
                                                                </i>
                                                            </Link>
                                                        </OverlayTrigger> 
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete Account</Tooltip>}>
                                                            <Link to="#">
                                                                <i className="material-symbols-outlined">
                                                                    delete
                                                                </i>
                                                            </Link>
                                                        </OverlayTrigger> 
                                                    </div>
                                                </td>: <></>}
                                            </tr>
                 
               // </FadeInOut> 
              )})
            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               </Container>
               {/* Start Modal */}<Modal size="lg" className="fade mt-4" id="post-modal" onHide={handleClose} show={show} >
               <div className="mt-4"></div>
                                    <Modal.Header  className="d-flex justify-content-between mt-4">
                                        
                                    {errMessage}
                                        <Modal.Title id="post-modalLabel">Send Email</Modal.Title>
                                        <Link to="#" className="lh-1" onClick={handleClose} >
                                            <span className="material-symbols-outlined">close</span>
                                        </Link>
                                        
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form className="mt-4">
                                    <Row className="justify-content-between d-flex">
                                    <Col sm="12" md="6">
                                    <Form.Group className="form-group">
                              <Form.Label>From</Form.Label>
                              <Form.Control type="text" className="mb-0" id="from" placeholder="Enter Email of Sender" name="from" onChange={handleChange} value={formData.from}/>
                           </Form.Group>
                           </Col>
                           <Col sm="12" md="6">
                           <Form.Group className="form-group">
                              <Form.Label>Subject</Form.Label>
                              <Form.Control type="text" className="mb-0" id="subject" placeholder="Enter Email Subject" name="subject" onChange={handleChange} value={formData.subject}/>
                           </Form.Group>
                           </Col>
                           <Col sm="12" md="12">
                           <Form.Group className="form-group">
                              <Form.Label>Message</Form.Label>
                              <Form.Control as="textarea" rows={3} className="mb-0" id="message" placeholder="Enter Message" name="message" onChange={handleChange} value={formData.message}/>
                           </Form.Group>
                           </Col>
                           
                           <Col sm="12" md="6">
                                            <button type="button" onClick={submitEmail} className="btn btn-success d-block w-80 mt-3 float-right">Submit</button>
                                            </Col>
                                            </Row>
                                        {loading ? 
                  <div className="mt-30">
                    {/* Load Spinner START */}
                    <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
                {/* Load Spinner END */}
                  </div>
                  : <></>}
                                    </Form>
                                    
                                    </Modal.Body>
                                </Modal>
               {/* End Modal */}
            </div>   
        </>
   )
}
export default MassEmail