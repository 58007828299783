import React from 'react'
import {Row, Col, Container, Form, Button, Image} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import MainLightLayout from '../imports/main-light';
import IntroUp from '../imports/IntroUp';
import AuthButtons from '../imports/AuthButtons';
import IntroOutBox from '../imports/IntroOut';
import Numbers from '../imports/Numbers';
import BubbleMap from '../imports/BubbleMaps';
import Footer from '../imports/Footer';
import data from '../imports/mapdata.jsx';
import CallToAction from '../imports/CallAction';
import Engage from '../imports/Engage';
import Section from '../imports/Section';



//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';




import Header from '../imports/Header1'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
        
   // let history =useNavigate()
   return (
      <>
       <MainLightLayout>
      <Header/>
      {/* <AuthButtons /> */}
      <IntroUp />
        <IntroOutBox />
        {/* <Container> */}
                {/* <Row>
        <Col lg="6"><Numbers /></Col> 
        <Col lg="6"><BubbleMap data={data}/></Col> 
                        </Row> */}
                {/* </Container> */}
                <CallToAction />
                <Engage />
        <Section />
        <Footer />
      </MainLightLayout>  
      </>
   )
}

export default SignIn
