import React, {useState, useRef, useEffect} from 'react'
import {Card, Image} from 'react-bootstrap'
import ScrollingView from '../ScrollingView'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';

//image
import user1 from '../../../../assets/images/user/01.jpg'
import user2 from '../../../../assets/images/user/02.jpg'
import user3 from '../../../../assets/images/user/03.jpg'
import user4 from '../../../../assets/images/user/04.jpg'
import user5 from '../../../../assets/images/user/11.jpg'
import user6 from '../../../../assets/images/user/12.jpg'

const RightSidebar = () => {

  // const user = JSON.parse(Cookies.get("user")).user
  // const token = JSON.parse(Cookies.get("user")).token

    const minirightsidebar =() =>{
        document.getElementById('rightSidebar').classList.toggle('right-sidebar');
        document.body.classList.toggle('right-sidebar-close');
    }

    

      const user = JSON.parse(Cookies.get("user")).user
  const token = JSON.parse(Cookies.get("user")).token

  const [opps, setOpps] = useState([]);

useEffect(() => {
axios.get('https://api.chulofilmhouse.co.ke/api/opps', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      const json = response.data;
      setOpps(json);
    })
    .catch(error => console.error(error));
}, []);
    return (
        <>
            <div className="right-sidebar-mini" id="rightSidebar">
                <div className="right-sidebar-panel p-0">
                    <Card className="shadow-none">
                       <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Opportunities</h4>
                                </div>
                                <div className="d-flex align-items-center">
                                    {/* <p className="m-0"><Link to="#"> Know More </Link></p> */}
                                </div>
                            </div>
                          
                        <Card.Body className="p-0">
                            <div className="media-height p-3" data-scrollbar="init">
                            <ScrollingView>
                            {!opps ?
                  <div>...</div>
               :
               Object.entries(opps).map((tab, index) => (
             
             <Card>
               
                <Card.Body>
                {/* <Link to={tab["1"].link}> */}
          <div className="d-flex align-items-center mb-4" key={index}>
            
            <div className="ms-3">
              <h6 className="mb-0"><div className="d-flex align-items-center"><div className={`flex iq-profiler-avatar me-2`}>
              {tab["1"].organisation_image !== null ? <Image className="rounded-circle avatar-20" src={`https://api.chulofilmhouse.co.ke/${tab["1"].organisation_image}`} alt="" loading="lazy" style={{objectFit:"cover"}}/> : <></>}
              
            </div> {tab["1"].title}</div></h6>
              <h6 className="mb-0 font-weight-bold" style={{fontWeight:'600'}}>{tab["1"].organisation}</h6>
              <p className="mb-0">{tab["1"].description.slice(0, 100)}</p>
             <h5><a href={tab["1"].link} className="mb-0 font-weight-bold" style={{fontWeight:'500'}}>Visit site</a></h5>
            </div>
          </div>
          {/* </Link> */}
           </Card.Body>
           </Card>
        ))}
      </ScrollingView>
                                {/* <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user1} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Anna Sthesia</h6>
                                        <p className="mb-0">Just Now</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paul Molive</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Anna Mull</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user4} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paige Turner</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user5} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Bob Frapples</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Barb Ackue</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Greta Life</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user6} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Ira Membrit</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user1} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Pete Sariya</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Monty Carlo</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex" onClick={minirightsidebar}>
                              <span className="material-symbols-outlined">chat</span>
                            </div> */}
                        </Card.Body>
                    </Card>
                    </div>
                </div>
            
        </>
    )
}

export default RightSidebar
