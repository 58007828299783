import React, {useState, useRef, useEffect}  from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import {Link} from 'react-router-dom'
import FadeInOut from '../FadeInOut'
import axios from 'axios';
import Cookies from 'js-cookie';
import ReadMoreText from './readMore'; // Replace with the correct path to the component

import imgm1 from "../../../assets/images/market/1.png";
import imgm2 from "../../../assets/images/market/2.png";
import imgm3 from "../../../assets/images/market/8.png";
import imgm4 from "../../../assets/images/market/3.png";
import imgm5 from "../../../assets/images/market/5.png";
import imgm6 from "../../../assets/images/market/9.png";
import imgm7 from "../../../assets/images/market/7.png";
const Opportunities = () => {
  const user = JSON.parse(Cookies.get("user")).user
  const token = JSON.parse(Cookies.get("user")).token

  const [opps, setOpps] = useState([]);

useEffect(() => {
axios.get('https://api.chulofilmhouse.co.ke/api/opps', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      const json = response.data;
      setOpps(json);
    })
    .catch(error => console.error(error));
}, []);

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
          <Col sm="8" md="8" className="">
            <div className="mb-2">
              <h4>Opportunities</h4>
              <p>Explore a curated collection of career openings, internship positions, transformative trainings, and beyond. You also have the power to contribute to this flourishing ecosystem.</p>
            </div>
            </Col>
            <Col sm="4" md="4">
            <Link to="/dashboards/form/opportunities">
            <Button variant="primary" name="next" className="next action-button float-end" value="Next" >Add Opportunity</Button>
            </Link>
            </Col>
            {
               !opps ?
                  <div>...</div>
               :
               Object.entries(opps).map((tab, index) => (
                
            <Col key={index}  sm="6" md="4">
              <FadeInOut
                show={true}
                >
              <Card className="cardhover">
                <Card.Body>
                  <Row>
                    <Col sm="10" md="10">
                   {tab["1"].organisation_image !== null ? <img
                    src={`https://api.chulofilmhouse.co.ke/${tab["1"].organisation_image}`}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                    loading="lazy"
                    style={{objectFit:"cover", width:"80%", height:"auto"}}
                  /> : <></>}
                  </Col>
                    <Col sm="2" md="2">
                    {tab["1"].verified === "1" ?
                    <span className="text-warning d-flex align-items-center line-height mt-0">
                    <i className="material-symbols-outlined md-18 me-1">verified</i>
                    
                    </span> 
                    : <></>} 
                    </Col>
                  </Row>
                  
                  <h5>{tab["1"].title}</h5>
                  <small>{`By: ${tab["1"].organisation}`}</small>
                  <div className="mt-2">
                    <p className="mb-0">
                    {/* Use the ReadMoreText component here */}
                <ReadMoreText text={tab["1"].description} maxCharCount={tab["1"].organisation_image !== null ? 100 :145} />
                    </p>
                  </div>
                  <hr className="text-warning d-flex align-items-center line-height mt-2 mb-2"/>
                  {tab["1"].type !== null && tab["1"].type !== "" ?
                  <span className="text-warning d-flex align-items-center line-height mt-0">
                    <i className="material-symbols-outlined md-18 me-1">business_center</i>
                    <small className="text-dark">{tab["1"].type}</small>
                  </span> :<></>}
                  <small className="mt-2">
                    <Link to={tab["1"].link}>
                      Visit<span> site</span>
                    </Link>
                  </small>
                </Card.Body>
              </Card>
              </FadeInOut>
            </Col>
               ))
             }
            
          </Row>
          {/* <Row>
            <div>
              <h4>New apps</h4>
              <p>Recent additions worth checking out</p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Channelyze</h5>
                  <small>Built by Channelyze Ltd</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Manage channel sales and partner renewals through HubSpot.
                    </p>
                  </div>
                  <div className="mt-4">
                    <small>New</small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Showpad by Elixir Solutions</h5>
                  <small>Built by Elixir Solutions</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Add Showpad email activity in HubSpot
                    </p>
                  </div>
                  <div className="mt-4">
                    <small>New</small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Ion by Rock Content</h5>
                  <small>Built by Rock Content</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Content experiences your audience will love
                    </p>
                  </div>
                  <div className="mt-4">
                    <small>New</small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Free apps</h4>
              <p>Explore free apps in the HubSpot Marketplace</p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Alexa Helper</h5>
                  <small>Built by Voicify</small>
                  <div className="mt-1">
                    <p className="mb-0">
                      Access your HubSpot data in real time through Amazon's
                      Alexa
                    </p>
                  </div>
                  <div className="mt-4">
                    <small>
                      <span>
                        <span>50</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Magento 2 Sync by Unific</h5>
                  <small>
                    Built by Unific | HubSpot Ecommerce Integrations
                  </small>
                  <div className="mt-1">
                    <p className="mb-0">
                      Take Data Driven Actions With Enriched Ecommerce Data
                    </p>
                  </div>
                  <div className="mt-1">
                    <small>
                      <span>
                        <span>40</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Pendo for HubSpot</h5>
                  <small>Built by Pendo</small>
                  <div className="mt-1">
                    <p className="mb-0">
                      Create product experiences people love
                    </p>
                  </div>
                  <div className="mt-4">
                    <small>
                      <span>
                        <span>50</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Built by HubSpot</h4>
              <p>Explore apps built and supported by HubSpot</p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm5}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Facebook Messenger</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Manage all Messenger conversations and bots in HubSpot
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">21</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>10,000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <div>
                    <img
                      loading="lazy"
                      src={imgm4}
                      alt="mimg"
                      className="avatar avatar-50 job-icon mb-2 d-inline-block "
                    />
                    <h5>Airtable</h5>
                    <small>Built by HubSpot</small>
                    <div className="mt-2">
                      <p className="mb-0">
                        Data Sync - Sync Airtable rows (with emails) with
                        HubSpot
                      </p>
                    </div>
                    <span className="text-warning d-flex align-items-center line-height mt-2">
                      <i className="material-symbols-outlined md-18 me-1">
                        star
                      </i>
                      <i className="material-symbols-outlined md-18 me-1">
                        star
                      </i>
                      <i className="material-symbols-outlined md-18 me-1">
                        star
                      </i>
                      <i className="material-symbols-outlined md-18 me-1">
                        star
                      </i>
                      <i className="material-symbols-outlined md-18 text-gray me-1">
                        star
                      </i>
                      <small className="text-dark">3</small>
                    </span>
                    <div className="mt-2">
                      <small>
                        <span>
                          <span>500</span>+ installs
                        </span>
                      </small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm6}
                    alt="mimg"
                    className="avatar avatar-50 market-job-icon job-icon mb-2 d-inline-block "
                  />
                  <h5>Mailchimp</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Data Sync - Sync contacts two-way with a Mailchimp
                      Audience
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">22</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>3,500</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Workflow integrations</h4>
              <p>Explore apps you can leverage within HubSpot workflows.</p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>360NRS</h5>
                  <small>Built by Net Real Solutions</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Send individual SMS to your contacts or use workflows to
                      send bulk SMS
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">3</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>50</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Aloware</h5>
                  <small>Built by Aloware</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Dialer, calling & texting automations with real-time
                      logging & syncing
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">4</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>100</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>SMS for HubSpot</h5>
                  <small>Built by MessageMedia</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Send and receive SMS without leaving HubSpot. Get started
                      in minutes.
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">13</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>300</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Data sync built by HubSpot</h4>
              <p>
                Explore powerful and easy-to-use two-way data sync for 25+ apps,
                built by HubSpot
              </p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm7}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Google Contacts</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0"></p>Sync Google Contacts with HubSpot
                    contacts
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">50</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>9000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm6}
                    alt="mimg"
                    className="avatar avatar-50 job-icon market-job-icon mb-2 d-inline-block "
                  />
                  <h5>Mailchimp</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Data Sync - Sync contacts two-way with a Mailchimp
                      Audience
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">22</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>3,500</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Stripe</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Data Sync - Sync Stripe and HubSpot customers
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">10</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>1,000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Marketing campaign apps</h4>
              <p>
                Explore apps that can help optimize your marketing campaign
                efforts in HubSpot.
              </p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Databox</h5>
                  <small>Built by Databox, Inc</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Popular KPI/Analytics Dashboards Amongst HubSpot Customers
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">35</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>10,000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Forminator</h5>
                  <small>Built by WPMU DEV</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Easy-to-create forms, polls, and quizzes for WordPress.
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">3</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>1,000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Lucky Orange</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p>Improve customer experience with recordings</p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">16</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>1,500</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <div>
              <h4>Media bridge apps</h4>
              <p>
                Combine the power of HubSpot’s CRM platform with your favorite
                media apps
              </p>
            </div>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Wistia</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0"></p>Sync Google Contacts with HubSpot
                    contacts
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">50</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>9000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm6}
                    alt="mimg"
                    className="avatar avatar-50 job-icon market-job-icon mb-2 d-inline-block "
                  />
                  <h5>Mailchimp</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Data Sync - Sync contacts two-way with a Mailchimp
                      Audience
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">22</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>3,500</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="cardhover">
                <Card.Body>
                  <img
                    loading="lazy"
                    src={imgm4}
                    alt="mimg"
                    className="avatar avatar-50 job-icon mb-2 d-inline-block "
                  />
                  <h5>Stripe</h5>
                  <small>Built by HubSpot</small>
                  <div className="mt-2">
                    <p className="mb-0">
                      Data Sync - Sync Stripe and HubSpot customers
                    </p>
                  </div>
                  <span className="text-warning d-flex align-items-center line-height mt-2">
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 me-1">star</i>
                    <i className="material-symbols-outlined md-18 text-gray me-1">
                      star
                    </i>
                    <small className="text-dark">10</small>
                  </span>
                  <div className="mt-2">
                    <small>
                      <span>
                        <span>1,000</span>+ installs
                      </span>
                    </small>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};
export default Opportunities;
