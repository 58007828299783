import React from 'react'
import { Container} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'

//profile-header
import ProfileHeader from '../../../components/profile-header'

// images
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import img51 from '../../../assets/images/page-img/51.jpg'
import img52 from '../../../assets/images/page-img/52.jpg'
import img53 from '../../../assets/images/page-img/53.jpg'
import img54 from '../../../assets/images/page-img/54.jpg'
import img55 from '../../../assets/images/page-img/55.jpg'
import img56 from '../../../assets/images/page-img/56.jpg'
import img58 from '../../../assets/images/page-img/58.jpg'
import img57 from '../../../assets/images/page-img/57.jpg'
import img59 from '../../../assets/images/page-img/59.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'

const ProfileEvents =() =>{
   return(
      <>
         <ProfileHeader title="Events" img="https://cdn.pixabay.com/photo/2017/11/24/10/43/ticket-2974645_1280.jpg"/>
            <div id="content-page" className="content-page">
               <Container>
                  <div className="d-grid gap-3 d-grid-template-1fr-19">
                     <div>
                        <Card className=" rounded  mb-0">
                           <div className="event-images">
                              <Link to="#">
                                 <img src="https://cdn.pixabay.com/photo/2019/03/18/13/40/eye-4063134_640.jpg" className="img-fluid" alt="Responsive" style={{minWidth:"100%"}}/>
                              </Link>
                           </div>
                           <Card.Body>
                              <div className="d-flex">
                                 <div className="date-of-event">
                                    <span>Jan</span>
                                    <h5>01</h5>
                                 </div>
                                 <div className="events-detail ms-2">
                                    {/* <h5><Link to="/dashboards/app/event-detail">Innovation Conclave: Uniting Minds for Breakthrough Solutions</Link></h5> */}
                                    <h5><Link to="#">Innovation Conclave: Uniting Minds for Breakthrough Solutions</Link></h5>
                                    <p>A dynamic gathering of experts, entrepreneurs, and visionaries from diverse disciplines, fostering collaborative thinking to drive innovation and create groundbreaking solutions to global challenges.</p>
                                    {/* <div className="event-member">
                                       <div className="iq-media-group">
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user05} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user06} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user07} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user08} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user09} alt=""/>
                                          </Link>
                                       </div>
                                    </div> */}
                                 </div>
                              </div>
                           </Card.Body>
                        </Card>
                     </div>
                    
                     
                  </div>
               </Container>
            </div>
      </>
  )

}

export default ProfileEvents;