import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX  from 'xlsx';
import axios from 'axios';
import Cookies from 'js-cookie';

const ImportDataComponent = () => {
  const user = JSON.parse(Cookies.get("user")).user
  const token = JSON.parse(Cookies.get("user")).token

  const [sheetName, setSheetName] = useState('tester sheet');
  const [fileData, setFileData] = useState(null);

  const handleSheetNameChange = (event) => {
    setSheetName(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Filter out rows with at least one cell
      const filteredRows = jsonData.filter((row) => row.some((cellValue) => cellValue !== undefined && cellValue !== ''));

      // Convert the array of arrays to an array of objects with the first row as titles
      const jsonWithTitles = filteredRows.slice(1).map((row) => {
        const obj = {};
        filteredRows[0].forEach((title, columnIndex) => {
          obj[title] = row[columnIndex] !== undefined ? row[columnIndex] : '';
        });
        return obj;
      });

      // Now, jsonWithTitles contains the JSON data with the first row as titles.
      console.log(jsonWithTitles);
      setFileData(jsonWithTitles);
    };

    reader.readAsBinaryString(file);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    readFile(uploadedFile);
    
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      const parsedData = file.name.endsWith('.csv')
        ? Papa.parse(contents).data
        : XLSX.read(contents, { type: 'binary' }).Sheets.Sheet1; // Adjust the sheet name accordingly

      // setFileData(parsedData);
    };
    reader.readAsBinaryString(file);
  };

  const handleDataImport = async() => {
    if (!sheetName || !fileData) {
      // Handle error: missing sheet name or no data to import
      return;
    }

    // axios.post('https://api.chulofilmhouse.co.ke/api/import', { sheet_name: sheetName, file_data: JSON.stringify(fileData) })
    //   .then((response) => {
    //     // Handle success, show a success message or perform any additional action
    //     console.log(response.data.message);
    //   })
    //   .catch((error) => {
    //     // Handle error, show an error message or perform any additional action
    //     console.error(error.response.data.error);
    //   });

      try {
        console.log("***********************");
        const response = await fetch('https://api.chulofilmhouse.co.ke/api/import', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sheet_name: "test Sheet", file_data: fileData }),
        });
    
        if (response.ok) {
          // Handle successful post
          const resData = await response.json();
          console.log(resData);
          
        } else {
          const errorData = await response.json();
          console.error('Post failure:', errorData);
          // setErrMessage(errorData.message);
          // Handle post failure, such as showing an error message to the user
        }
      } catch (error) {
        console.error('Posting failed:', error);
        // Handle post failure, such as showing an error message to the user
      }
  };

  return (
    <div className="ml-12">
       
      <input type="text" placeholder="Sheet Name" value={sheetName} onChange={handleSheetNameChange} />
      <input type="file" onChange={handleFileUpload} />
      <button onClick={handleDataImport}>Import Data</button>
    </div>
  );
};

export default ImportDataComponent;
