import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useAuthentication = () => {
  var token 
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if(Cookies.get("user") !== undefined){
      token = JSON.parse(Cookies.get("user")).token
      setIsLoggedIn(true)
    }
  },[token, isLoggedIn])

  useEffect(() => {
    // Check if the user object exists in session storage or cookie
    // const userObject = localStorage.getItem('user');
    // Or if you stored it in a cookie
    const userObject = Cookies.get('user');

    if (userObject) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, token]);

  return isLoggedIn;
};

export default useAuthentication;
