
import { useState } from "react";
import accordionsData from './accordions.json';

import logo from '../../../../assets/images/logo.png'
import usaid from '../../../../assets/images/usaid.png'

const Section = () => {
  const [accordions, setAccordions] = useState(accordionsData);

  const openAccordion = (e) => {
    document.querySelectorAll('.accordion.bord .item').forEach(accordion => accordion.classList.remove('active'))

    let item = e.target.closest('.item');
    item.classList.add('active');

    let newAccordions = accordions.map((accordion, idx) => {
      if (item.id.split('-')[1] === idx) accordion.closed = false;
      else accordion.closed = true;

      return accordion;
    });

    setAccordions(newAccordions);
  }

  return (
    <section className="section-paddingf pb-1 pt-1 px-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 valign">
            <div className="content">
              <div className="sub-title gr-purple-red-text inline">
                <span>Reach Out</span>
              </div>
              {/* <h4 className="mb-40">Meaningful connections, shared experiences, and up-to-date content.</h4> */}

              <div className="bord">
              <div className="title">
                        <h6 className="fz-15">Africa One Health University Network Kenya</h6>
                        <span className="ico">c/o University of Nairobi</span><br/>
                        <span className="ico">Faculty of Veterinary Medicine</span><br/>
                        {/* <span className="ico">P.O. Box 29053-00625</span><br/> */}
                        {/* <span className="ico">Email: kenya@afrohun.org</span><br/> */}
                        {/* <span className="ico">Web: www.afrohun.org</span> */}
                      </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 wow slideInUp">
          <div className="sub-title gr-purple-red-text inline pt-2">
                {/* <span>Reach Out</span> */}
              </div>
            <div className="bord">
            <div className="title">
                        {/* <h6 className="fz-15">Africa One Health University Network Kenya</h6> */}
                        {/* <span className="ico">c/o University of Nairobi</span><br/> */}
                        {/* <span className="ico">Faculty of Veterinary Medicine</span><br/> */}
                        <span className="ico">P.O. Box 29053-00625</span><br/>
                        <span className="ico">Email: kenya@afrohun.org</span><br/>
                        <span className="ico">Web: www.afrohun.org</span>
                      </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section