import {Link} from 'react-router-dom'
import {Row, Col, Container, Button, Dropdown, ButtonGroup, ToggleButton} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FormWizardEngageUs from "../../from/form-wizard-engage-us";
import FormWizardDonate from "../../from/form-wizard-donate";
import FormWizardSponsor from "../../from/form-wizard-sponsor";
import FormWizardPartner from "../../from/form-wizard-partner";

const Engage = () => {
  return (
    <section className="bg-gray mt-4 mb-4" >
      <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="home" title="Engage Us">
        <FormWizardEngageUs/>
      </Tab>
      <Tab eventKey="profile" title="Donate">
        <FormWizardDonate/>
      </Tab>
      <Tab eventKey="longer-tab" title="Sponsor">
        <FormWizardSponsor/>
      </Tab>
      <Tab eventKey="contact" title="Partner">
        <FormWizardPartner/>
      </Tab>
    </Tabs>
    </section>
  );
}

export default Engage