//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"


// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions'
import {  createBrowserRouter, RouterProvider } from 'react-router-dom'

import { IndexRouters } from './router';
import { IntroRouter } from './router/introRouter';
import { SimpleRouter } from './router/simple-router';
import { ChatRouter } from './router/chat-router';

import useAuthentication from './views/dashboard/auth'

function App() {
  const dispatch = useDispatch()
  dispatch(setSetting())
  const isLoggedIn = useAuthentication();

  const router = isLoggedIn ? createBrowserRouter([

    ...IndexRouters,
    ...SimpleRouter,
    ...ChatRouter
  ], { basename: process.env.PUBLIC_URL }) : createBrowserRouter([

    ...IntroRouter,
    ...SimpleRouter,
    ...ChatRouter
  ], { basename: process.env.PUBLIC_URL })


  return (
    <div className="App">
      {/* <IndexRouters /> */}
      <RouterProvider router={router}>
      
      </RouterProvider>
    </div>
  );
}

export default App;
