import React, {useState, useRef, useEffect} from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link, useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import axios from 'axios';
import FadeInOut from '../FadeInOut'
import Chat from './chat'

//profile-header
import ProfileHeader from '../../../components/profile-header'

// image
import img1 from '../../../assets/images/page-img/profile-bg2.jpg'
import img2 from '../../../assets/images/page-img/profile-bg1.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img8 from '../../../assets/images/page-img/profile-bg8.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import user15 from '../../../assets/images/user/15.jpg'
import user16 from '../../../assets/images/user/16.jpg'
import user17 from '../../../assets/images/user/17.jpg'
import user18 from '../../../assets/images/user/18.jpg'
import user19 from '../../../assets/images/user/19.jpg'


const FriendList =() =>{
    const history =useNavigate();

    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const [people, setPeople] = useState([]);

    const [trigger, setTrigger] = useState(false);

useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
axios.get('https://api.chulofilmhouse.co.ke/api/users', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      const json = response.data;
      setPeople(json);
    })
    .catch(error => console.error(error));
}, [trigger]);

const handleChatSelection = (selected) => {
    // Perform any action you need before navigating to the chat page with the selected user's chat
    console.log('Selected User:', selected);

    // Navigate to the chat page with the selected user's chat
    // Replace '/chat' with the correct path to the Chat component route
    // For example, if you're using React Router, it could be '/chat/:userId' where :userId is the selected user's ID
    // You can pass the user data as a state or query parameter, depending on your routing setup
    // Here, we're passing it as a state parameter
    history('/chat/index' , { state:  selected });

  };

    return(
        <>
            {/* <ProfileHeader title="Friend Lists" img={img3}/> */}
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img src={"https://cdn.pixabay.com/photo/2019/10/06/10/03/team-4529717_640.jpg"} className="img-fluid w-100" alt="header-bg" style={{height:"50vh", objectFit:"cover"}}/>
                    {/* <Link onClick={handleShow} to="#" className="iq-media"> */}
                    <div className="title-on-header">
                        <div className="data-block">
                        
                        <h3>{"People"}</h3>
                          
                        </div>
                    </div>
                    {/* </Link> */}
                </div>
            </div>
                <div id="content-page" className="content-page">
                    <Container>
                        <Row>
                            
                            {
               !people ?
                  <div>...</div>
               :
               Object.entries(people).map((tab, index) => (
                <Col key={index}  md={6}>
                <FadeInOut 
                show={true}
                >
                                <Card className=" card-block card-stretch card-height">
                                    <Card.Body className=" profile-page p-2">
                                        <div className="profile-header-image">
                                            <div className="cover-container">
                                                <img loading="lazy" src={"https://cdn.pixabay.com/photo/2020/11/07/01/28/abstract-5719221_640.jpg"} alt="profile-bg" className="rounded img-fluid w-100"  style={{height:"15vh", objectFit:"cover"}}/>
                                            </div>
                                            <div className="profile-info p-4">
                                                <div className="user-detail">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                                                        <div className="profile-detail d-flex">
                                                            <div className="profile-img pe-4">
                                                                <img loading="lazy" src={!(tab["1"].profile_picture) ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png' :tab["1"].profile_picture} alt="profile-img" className="avatar-80 img-fluid" style={{height:"15vh",width: "15vh", objectFit:"cover"}} />
                                                            </div>
                                                            <div className="user-data-block">
                                                                <h4>
                                                                    <Link to="/dashboard/app/friend-profile">{tab["1"].name}</Link>
                                                                </h4>
                                                                <h6>{tab["1"].career}</h6>
                                                                <p>{tab["1"].organisation}</p>
                                                            </div>
                                                        </div>
                                                        <button onClick={() => handleChatSelection(tab["1"])} type="button" className="btn btn-primary">Chat</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                
                                </FadeInOut>
                                </Col>
                           ))
                        }
                            
                            
                        </Row>
                    </Container>
                </div>
        </>
  )

}

export default FriendList;