import {Link} from 'react-router-dom'
import {Row, Col, Container, Button, Dropdown, ButtonGroup, ToggleButton} from 'react-bootstrap'

const IntroOutBox = () => {
  return (
    <section className="intro-out-box section-padding bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="img wow fadeInLeft" data-wow-delay=".3s" data-wow-duration="2s">
              <img src="https://cdn.pixabay.com/photo/2020/05/18/16/17/social-media-5187243__340.png" alt="" />
              <div className="svg-color">
                {/* <svg x="0px" y="0px" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg1">
                  <defs>
                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="1000%" style={{ stopColor: '#e8f0f4' }}></stop>
                      <stop offset="0%" style={{ stopColor: '#e8f0f4' }}></stop>
                    </linearGradient>
                  </defs>
                  <path fill="url(#gradient1)" id="blob1" d="">
                    <animate repeatCount="indefinite" attributeName="d" dur="12s" values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z" />
                  </path>
                </svg> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="cont">
              <h6 className="sub-title gr-purple-red-text inline">Join our vibrant community of AFROHUN alumni</h6>
              <h4>Designed exclusively for you, connecting you with fellow alumni.</h4>

              <div className="flex mt-10">
                <div className="item mr-30 wow fadeInUp" data-wow-delay=".1s">
                  <h4 className="fw-300 mb-10">...</h4>
                  <h6 className="mb-5">A prestigious network </h6>
                  <p>A space to foster meaningful connections, share experiences, and stay updated on the latest news and events.</p>
                </div>
                <div className="item wow fadeInUp" data-wow-delay=".3s">
                  <h4 className="fw-300 mb-10">...</h4>
                  <h6 className="mb-5">A bridge</h6>
                  <p>An enabler to collaboration, learning from one another, and continuing to make a positive impact in our respective fields through the One Health approach.</p>
                </div>
              </div>
              <div className="flex mt-10">
                <div className="item mr-30 wow fadeInUp" data-wow-delay=".1s">
                  {/* <h4 className="fw-300 mb-10">...</h4>
                  <h6 className="mb-5">A prestigious network </h6> */}
                  <p style={{color:"#395f9e"}}>Click here to know more about AFROHUN-Kenya</p>
                  <Button href="https://afrohun.org/kenya" variant="primary" className="mb-1 ml-15 gr-purple-bg butn butn-md text-light">
              <span className="text slide-up">AFROHUN</span>
                  <span className="text slide-down">Kenya</span>
                              </Button>
                </div>
                <div className="item wow fadeInUp" data-wow-delay=".3s">
                  {/* <h4 className="fw-300 mb-10">...</h4> */}
                 {/* <h6 className="mb-5">A bridge</h6> */}
                  <p style={{color:"#395f9e"}}>Click here to learn about the One Health Workforce Academies’ (OHWA)</p>
                  <Button href="https://onehealthworkforceacademies.org/about-ohwa/" variant="primary" className="mb-1 ml-15 gr-purple-bg butn butn-md text-light">
              <span className="text slide-up">About</span>
                  <span className="text slide-down">OHWA</span>
                              </Button>
                </div>
              </div>
              

              {/* <div className="mt-40">
              
                 <Link
                  href="https://afrohun.org/about-us/"
                  className="butn butn-md gr-purple-red-bg text-light radius-30">

                  <span className="text slide-up">About</span>
                  <span className="text slide-down">AFROHUN</span>

                </Link> 
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroOutBox