import React from 'react'
import { Link} from 'react-router-dom'
import {Row, Col, Container} from 'react-bootstrap'

const Footer = (props) => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('dark');
    if (mode !== null) {
      document.body.classList=''
    switch (mode) {
        case "true":
            document.body.classList='dark'
        break;
        case "false":
             document.body.classList=''
        break;
        default:
             document.body.classList=''
            break;
    }
    }

    const currentYear = new Date().getFullYear();
    return (
            <>
                {/* <footer className="iq-footer bg-white"> */}
                <footer className="bg-white">
                    <Container fluid>
                        <Row>
                            
                            <Col lg="12" className="d-flex justify-content-center mt-4">
                                <span style={{
                                        fontWeight: "800",
                                        alignItems: "center",
                                        fontSize: "12.5px",
                                        textAlign: "center"
                                }}>This platform is made possible by the generous support of the American people through the United States Agency for International Development (USAID). The contents are the responsibility of Africa One Health University Network and do not necessarily reflect the views of USAID or the United States Government.</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <ul className="list-inline mb-2 mt-4">
                                    <li className="list-inline-item"><Link to="#">Privacy Policy</Link></li>{' '}
                                    <li className="list-inline-item"><Link to="#">Terms of Use</Link></li>
                                </ul>
                            </Col>
                            <Col lg="6" className="d-flex justify-content-end mb-2 mt-4">
                                <span>AFROHUN <Link to="#"> &copy; {currentYear} </Link> All Rights Reserved</span>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
        )
}

export default (Footer)