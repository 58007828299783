import React, {useState, useRef, useEffect}  from 'react'
import {Container, Card, Row, Col, Image, Form, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';

// img
import image1 from '../../../assets/images/page-img/img-success.png'
import img1 from '../../../assets/images/small/07.png'

const FormWizardOpportunities = () => {
    const [show, AccountShow] = useState('A');
    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const hiddenFileInput = useRef(null);
    const [loading, setLoading] = useState(false);
    const [nuller, setNuller] = useState(false);

    const [postImage, setPostImage] = useState();
    const [postMedia, setPostMedia] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [trigger, setTrigger] = useState(false);

    const [formData, setFormData] = useState({
      title:"",
      link:"",
      description:"",
      deadline:"",
      organisation:"",
      organisation_image:null,
      type:"",
      });
    



      function getUserByID(id){
     var postUser =  fetch(`https://api.chulofilmhouse.co.ke/api/users/${id}`,
    {headers: { "Content-Type": "application/json" , "Accept":"application/json", "Authorization":`Bearer ${token}`}})
      .then(response => response.json())
      .then(json =>{
        return json
        })
      .catch(error => console.error(error));
      
      return postUser;
      }

      

      const handleClick = event => {
        hiddenFileInput.current.click();
      };

    function handleMediaChange(e) {
        console.log(e.target.files);
        setPostImage(URL.createObjectURL(e.target.files[0]));
        setPostMedia(e.target.files[0]);
        
    }

    const handleInputChange = (e) => {
        setFormData(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
      }

      const validateForm = (formValues) => {
        if ( !formValues.title || !formValues.description || !formValues.organisation) {
          setErrMessage('Fields marked with * cannot be empty');
          return false;
        }
        return true;
      }

      const handlePost = async () => {
        // setLoading(true)

       var postData = new FormData()
       postData.append("title", formData.title)
       postData.append("link", formData.link)
       postData.append("description", formData.description)
       postData.append("deadline", formData.deadline)
       postData.append("organisation", formData.organisation)
       !postMedia ? setNuller(true): postData.append("organisation_image", postMedia)
       postData.append("type", formData.type)
       
       for (let pair of postData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
        try {
      
          const response = await fetch('https://api.chulofilmhouse.co.ke/api/opps', {
            method: 'POST',
            headers: {
            //   'Content-Type': 'multipart/form-data',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: postData,
          });
      
          if (response.ok) {
            // Handle successful post
            const resData = await response.json();
            console.log(resData);
            setFormData({
              title: '',
              organisation: '',
              link: '',
              description: '',
              type: '',
              deadline: '',
              organisation_image: null,
            });
            setPostMedia(null)
            setLoading(false)
            setTrigger(prevTrigger => !prevTrigger);
          } else {
            const errorData = await response.json();
            console.error('Post failure:', errorData);
            setErrMessage(errorData.message);
            // Handle post failure, such as showing an error message to the user
          }
        } catch (error) {
          console.error('Posting failed:', error);
          // Handle post failure, such as showing an error message to the user
        }
        
      };

      const submitPost = (e) =>{
        e.preventDefault();
        setLoading(true)
    
        //= Validate Form
        if (!validateForm(formData)) return;
        // setLoading(true)
        //= Clear Error Message
        setErrMessage('');
    
        handlePost();
      }
    return (
        <>
        <div id='content-page' className='content-page'>
            <Container>
                <Row>
                    <Col sm="12">
                        <Card className="position-relative inner-page-bg bg-transparent" style={{height: "70px"}}>
                            <div className="inner-page-title">
                                {/* <h3 className="text-white">Simple Wizard Page</h3>
                                <p className="text-white">lorem ipsum</p> */}
                                <Link to="/dashboards/opportunities">
            <Button variant="primary" name="next" className="next action-button float-end" value="Next" >View Opportunities</Button>
            </Link>
                            </div>
                        </Card>
                    </Col>
                    
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                {errMessage}
                            </Card.Header>
                            <Card.Body>
                            {!postMedia ? <></>:
                                        <div className="user-post mt-3">
                                        <Link to="#"><img src={postImage} alt="post1" className="img-fluid rounded w-100" style={{height: "255px",
    objectFit: "cover"}}/></Link>
                                    </div>}
                                <Form id="form-wizard1" className="text-center mt-3">
                                    {/* <ul id="top-tab-list" className="p-0 row list-inline"> */}
                                        {/* <li className={ ` ${show === 'Image' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} ${show === 'A' ? 'active ' : ''} col-lg-3 col-md-6 text-start  active`} id="account">
                                            <Link to="#">
                                                <i className="material-symbols-outlined">edit_note</i><span>Opportunity</span>
                                            </Link>
                                        </li> */}
                                       {/* <li className={ ` ${show === 'Image' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} ${show === 'A' ? 'active ' : ''} col-lg-3 col-md-6 text-start mb-2 active`} id="account">
                                            <Link to="#">
                                                <i className="material-symbols-outlined">lock_open</i><span>Account</span>
                                            </Link>
                                        </li>
                                         <li id="personal" className={` ${show === 'Image' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">person</i><span>Personal</span>
                                            </Link>
                                        </li>
                                        <li id="payment" className={` ${show === 'Image' ? 'active done': ''} ${show === 'Personal' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">photo_camera</i><span>Image</span>
                                            </Link>
                                        </li>
                                        <li id="confirm" className={` ${show === 'Image' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">done</i><span>Finish</span>
                                            </Link>
                                        </li> */}
                                    {/* </ul> */}
                                    <fieldset className={`${show === 'A' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            {/* <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Account Information:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 1 - 4</h2>
                                                </div>
                                            </Row> */}
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Title: *</Form.Label>
                                                        <Form.Control type="text" name="title" placeholder="Opportunity name/title" onChange={handleInputChange} value={formData.title}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Organisation: *</Form.Label>
                                                        <Form.Control type="text" name="organisation" placeholder="Organisation" onChange={handleInputChange} value={formData.organisation}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Description: *</Form.Label>
                                                        <Form.Control as="textarea" rows={3} name="description" placeholder="Brief Description" onChange={handleInputChange} value={formData.description}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Link: *</Form.Label>
                                                        <Form.Control type="text" name="link" placeholder="External link" onChange={handleInputChange} value={formData.link}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                <Form.Group className="form-group">
    <Form.Label>Opportunity Type</Form.Label>
    <Form.Control
      as="select"
      className="mb-0"
      name="type"
      onChange={handleInputChange}
      value={formData.type}
    >
      {/* <option value="">Select Account Type</option>
      {accountTypeOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option> 
      ))}*/}
      <option value="">Select Opportunity Type</option>
      <option value="Full Time">Full Time</option>
      <option value="Internship">Internship</option>
      <option value="Attachement">Attachement</option>
      <option value="Research Assistant">Research Assistant</option>
      <option value="Student">Student</option>
      <option value="Part Time">Part Time</option>
    </Form.Control>
  </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Deadline: *</Form.Label>
                                                        <Form.Control type="date" name="deadline" placeholder="Deadline" onChange={handleInputChange} value={formData.deadline}/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                        <Col md="4">
                                        <input type="file" 
                                        ref={hiddenFileInput}
                                        accept="image/*"
                                        style={{ display: 'none' }} 
                                        onChange={handleMediaChange} 
                                        />
                                            <Link to="#" className="btn btn-soft-primary" onClick={handleClick}>
                                                <img src={img1} alt="icon" className="img-fluid"/> 
                                            </Link>
                                            </Col>
                                                <Col md="6">
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Next" onClick={submitPost} >Submit</Button>
                                        </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset className={`${show === 'Account' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Personal Information:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 2 - 4</h2>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>First Name: *</Form.Label>
                                                        <Form.Control type="text" name="fname" placeholder="First Name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Last Name: *</Form.Label>
                                                        <Form.Control type="text" name="lname" placeholder="Last Name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Contact No.: *</Form.Label>
                                                        <Form.Control type="text" name="phno" placeholder="Contact No." />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Alternate Contact No.: *</Form.Label>
                                                        <Form.Control type="text" name="phno_2" placeholder="Alternate Contact No." />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Next" onClick={() => AccountShow('Personal')} >Next</Button>
                                        <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('A')} >Previous</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Image Upload:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 3 - 4</h2>
                                                </div>
                                            </Row>
                                            <Form.Group className="form-group">
                                                <Form.Label>Upload Your Photo:</Form.Label>
                                                <Form.Control type="file" name="pic" accept="image/*"/>
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Upload Signature Photo:</Form.Label>
                                                <Form.Control type="file" name="pic-2" accept="image/*"/>
                                            </Form.Group>
                                        </div>
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Submit" onClick={() => AccountShow('Image')}>Submit</Button>
                                        <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('Account')} >Previous</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Image' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4 text-left">Finish:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 4 - 4</h2>
                                                </div>
                                            </Row>
                                            <br/><br/>
                                            <h2 className="text-success text-center"><strong>SUCCESS !</strong></h2>
                                            <br/>
                                            <Row className="justify-content-center">
                                                <div className="col-3"> 
                                                    <Image src={image1} className="img-fluid" alt="fit-image"/>
                                                </div>
                                            </Row>
                                            <br/><br/>
                                            <Row className="justify-content-center">
                                                <div className="col-7 text-center">
                                                    <h5 className="purple-text text-center">You Have Successfully Signed Up</h5>
                                                </div>
                                            </Row>
                                        </div>
                                    </fieldset>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default FormWizardOpportunities
