import React, {useState, useRef, useEffect} from 'react'
import { Container, Modal} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
// import ProfileHeader from '../../../components/profile-header'
import Cookies from 'js-cookie'
import axios from 'axios';
import FadeInOut from '../FadeInOut'

// images
import gi1 from '../../../assets/images/page-img/gi-1.jpg'
import gi2 from '../../../assets/images/page-img/gi-2.jpg'
import gi3 from '../../../assets/images/page-img/gi-3.jpg'
import gi4 from '../../../assets/images/page-img/gi-4.jpg'
import gi5 from '../../../assets/images/page-img/gi-5.jpg'
import gi6 from '../../../assets/images/page-img/gi-6.jpg'
import gi7 from '../../../assets/images/page-img/gi-7.jpg'
import gi8 from '../../../assets/images/page-img/gi-8.jpg'
import gi9 from '../../../assets/images/page-img/gi-9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img2 from '../../../assets/images/page-img/profile-bg2.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'

import imgclick from '../../../assets/images/small/07.png'

const Groups =() =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const hiddenFileInput = useRef(null);
    const [loading, setLoading] = useState(false);

    const [postImage, setPostImage] = useState();
    const [postMedia, setPostMedia] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [trigger, setTrigger] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        group_image: null
      });
    

const [groups, setGroups] = useState([]);

useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
axios.get('https://api.chulofilmhouse.co.ke/api/groupz_info', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      const json = response.data;
      setGroups(json);
    })
    .catch(error => console.error(error));
}, [trigger]);


      function getUserByID(id){
     var postUser =  fetch(`https://api.chulofilmhouse.co.ke/api/users/${id}`,
    {headers: { "Content-Type": "application/json" , "Accept":"application/json", "Authorization":`Bearer ${token}`}})
      .then(response => response.json())
      .then(json =>{
        return json
        })
      .catch(error => console.error(error));
      
      return postUser;
      }


      function joinGroup(e, id){
        e.preventDefault()
     var postUser =  fetch(`https://api.chulofilmhouse.co.ke/api/groupz/${id}/join`,
    {method: 'POST',headers: { "Content-Type": "application/json" , "Accept":"application/json", "Authorization":`Bearer ${token}`}})
      .then(response => response.json())
      .then(json =>{
        setTrigger(prevTrigger => !prevTrigger);
        return json
        })
      .catch(error => console.error(error));
      
      return postUser;
      }

      function leaveGroup(e, id){
        e.preventDefault()
     var postUser =  fetch(`https://api.chulofilmhouse.co.ke/api/groupz/${id}/leave`,
    {method: 'POST',headers: { "Content-Type": "application/json" , "Accept":"application/json", "Authorization":`Bearer ${token}`}})
      .then(response => response.json())
      .then(json =>{
        setTrigger(prevTrigger => !prevTrigger);
        return json
        })
      .catch(error => console.error(error));
      
      return postUser;
      }

      

      const handleClick = event => {
        hiddenFileInput.current.click();
      };

    function handleMediaChange(e) {
        console.log(e.target.files);
        setPostImage(URL.createObjectURL(e.target.files[0]));
        setPostMedia(e.target.files[0]);
        
    }

    const handleInputChange = (e) => {
        setFormData(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
      }

      const validateForm = (formValues) => {
        if ( !formValues.name || !formValues.description) {
          setErrMessage('Fields cannot be empty');
          return false;
        }
        return true;
      }

      const handlePost = async () => {
        // setLoading(true)
        console.log(formData.name);
        console.log(formData.description);
        console.log(postMedia);

       var postData = new FormData()
       postData.append("name", formData.name)
       postData.append("description", formData.description)
       postData.append("group_image", postMedia)
        try {
      
          const response = await fetch('https://api.chulofilmhouse.co.ke/api/groupz', {
            method: 'POST',
            headers: {
            //   'Content-Type': 'multipart/form-data',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: postData,
          });
      
          if (response.ok) {
            // Handle successful post
            const resData = await response.json();
            console.log(resData);
            setFormData({
              name: '',
              group_image: null,
            });
            setPostMedia(null)
            setLoading(false)
            setShow(false)
            setTrigger(prevTrigger => !prevTrigger);
          } else {
            const errorData = await response.json();
            console.error('Post failure:', errorData);
            // Handle post failure, such as showing an error message to the user
          }
        } catch (error) {
          console.error('Posting failed:', error);
          // Handle post failure, such as showing an error message to the user
        }
        
      };

      const submitPost = (e) =>{
        e.preventDefault();
        setLoading(true)
    
        //= Validate Form
        if (!validateForm(formData)) return;
        // setLoading(true)
        //= Clear Error Message
        setErrMessage('');
    
        handlePost();
      }

    return(
        <>
            {/* <ProfileHeader  img="https://cdn.pixabay.com/photo/2015/09/17/10/33/personal-943873_640.jpg" title="Groups" /> */}
            <div className="header-for-bg">
                <div className="background-header position-relative">
                    <img src={"https://cdn.pixabay.com/photo/2015/09/17/10/33/personal-943873_640.jpg"} className="img-fluid w-100" alt="header-bg" style={{height:"50vh", objectFit:"cover"}}/>
                    <Link onClick={user.role === "SuperAdmin" ? handleShow :()=>{}} to="#" className="iq-media">
                    <div className="title-on-header">
                        <div className="data-block">
                        
                        <h3>{user.role === "SuperAdmin" ? "Create New Group" : "Groups"}</h3>
                          
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
                
                <div id="content-page" className="content-page">
                    <Container>
                        <div className="d-grid gap-3 d-grid-template-1fr-19">
                        {
               !groups ?
                  <div>...</div>
               :
               Object.entries(groups).map((tab, index) => (
                <FadeInOut key={index} 
                show={true}
                >
                
             
                            <Card className="mb-0">
                                <div className="top-bg-image">
                                    <img src={"https://cdn.pixabay.com/photo/2017/09/30/19/05/cubes-2803223_640.jpg "} className="img-fluid w-100" alt="group-bg" style={{height:"15vh", objectFit:"cover"}}/>
                                </div>
                                <Card.Body className=" text-center">
                                    <div className="group-icon">
                                        <img src={`https://api.chulofilmhouse.co.ke/${tab["1"].group_image}`} alt="profile-img" className="rounded-circle img-fluid avatar-120" style={{objectFit:"cover"}}/>
                                    </div>
                                    <div className="group-info pt-3 pb-3">
                                        <h4><Link to="/dashboards/app/group-detail">{tab["1"].name}</Link></h4>
                                        {/* <p>Lorem Ipsum data</p> */}
                                    </div>
                                    <div className="group-details d-inline-block pb-3">
                                        <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                                            {/* <li className="pe-3 ps-3">
                                                <p className="mb-0">Post</p>
                                                <h6>600</h6>
                                            </li> */}
                                            <li className="pe-3 ps-3">
                                                <p className="mb-0">Members</p>
                                                <h6>{tab["1"].members.length}</h6>
                                            </li>
                                            {/* <li className="pe-3 ps-3">
                                                <p className="mb-0">Visit</p>
                                                <h6>{tab["1"].members.some(m => m.id === user.id).toString()}</h6>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="group-member mb-3">
                                        <div className="iq-media-group">
                                        {
               !tab["1"].members ?
                  <div>...</div>
               :
               Object.entries(tab["1"].members).map((mem, memIndex) => (
                
                                            <Link key={memIndex} to="#" className="iq-media">
                                                <img className="img-fluid avatar-40 rounded-circle" src={mem["1"].profile_picture} alt="" style={{objectFit:"fill"}}/>
                                            </Link>

                           ))
                        }
                                            
                                        </div>
                                    </div>
                                    { tab["1"].members.some(m => m.id === user.id) ?
                                    <button  onClick={(e)=>leaveGroup(e,tab["1"].id)} type="button" className="btn btn-danger d-block w-100">Leave Group</button>
                                    :<button  onClick={(e)=>joinGroup(e,tab["1"].id)} type="button" className="btn btn-primary d-block w-100">Join Group</button>
}
                                </Card.Body>
                            </Card>
                            </FadeInOut>
                           ))
                        }
                            
                        </div>
                    </Container>
                    <Modal size="lg" className="fade" id="post-modal" onHide={handleClose} show={show} >
                                    <Modal.Header  className="d-flex justify-content-between">
                                        <Modal.Title id="post-modalLabel">Create Post</Modal.Title>
                                        <Link to="#" className="lh-1" onClick={handleClose} >
                                            <span className="material-symbols-outlined">close</span>
                                        </Link>
                                        
                                    </Modal.Header>
                                    <Modal.Body>
                                    <form>
                                    <div className="other-option">
                                    {errMessage}
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                               {/* <h1>This is to be an image holder</h1> */}
                                               
                                               {!postMedia ? <></>:
                                        <div className="user-post mt-3">
                                        <Link to="#"><img src={postImage} alt="post1" className="img-fluid rounded w-100" style={{height: "255px",
    objectFit: "cover"}}/></Link>
                                    </div>}
                                                </div>
                                                <div className="card-post-toolbar">
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                       
                                        <div className="d-flex align-items-center mt-4">
                                           
                                            <div className="post-text ms-3 w-100 "  data-bs-toggle="modal" data-bs-target="#post-modal">
                                            <input type="text" className="form-control rounded" placeholder="Group Name" style={{border:"none"}} name="name" onChange={handleInputChange} value={formData.name}/>
                                        </div>
                                        </div>
                                        <hr/>
                                        <div className="d-flex align-items-center mt-4">
                                           
                                            <div className="post-text ms-3 w-100 "  data-bs-toggle="modal" data-bs-target="#post-modal">
                                            <input type="textarea" className="form-control rounded" placeholder="Group Description" style={{border:"none"}} name="description" onChange={handleInputChange} value={formData.description}/>
                                        </div>
                                        </div>
                                        <hr/>
                                        <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                                        <li className="me-2 mb-md-0 mb-2">
                                        <input type="file" 
                                        ref={hiddenFileInput}
                                        accept="image/*"
                                        style={{ display: 'none' }} 
                                        onChange={handleMediaChange} />
                                            <Link to="#" className="btn btn-soft-primary" onClick={handleClick}>
                                                <img src={imgclick} alt="icon" className="img-fluid"/> 
                                            </Link>
                                        </li>
                                            
                                        </ul>
                                        
                                        <button type="button" onClick={submitPost} className="btn btn-primary d-block w-80 mt-3 float-right">Create Group</button>
                                        {loading ? 
                  <div className="mt-30">
                    {/* Load Spinner START */}
                    <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
                {/* Load Spinner END */}
                  </div>
                  : <></>}
                                    </form>
                                    </Modal.Body>
                                </Modal>
                </div>
        </>
  )

}

export default Groups;