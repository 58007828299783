import React, {useState, useRef, useEffect}  from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown} from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import axios from 'axios';

//img
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
// import page100 from '../../../assets/images/page-img/100.jpg'

import Cookies from 'js-cookie';

const ChatPane=({show,show1, show2, pal})=>{
    // const [show, setShow] = useState('')
    // const [show1, setShow1] = useState('')
    // const [show2, setShow2] = useState('')
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }

    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const [messages, setMessages] = useState([]);

    const [sendMessage, setSendMessage] = useState({
        content:""
    });

    useEffect(() => {
        const token = JSON.parse(Cookies.get("user")).token;
      
        const fetchMessages = async () => {
          try {
            if (pal.chat === "private") {
              const messagesSentResponse = await axios.get('https://api.chulofilmhouse.co.ke/api/messages', {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
      
              const messagesReceivedResponse = await axios.get('https://api.chulofilmhouse.co.ke/api/messages/1', {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
      
              const messagesSent = messagesSentResponse.data;
              const messagesReceived = messagesReceivedResponse.data;
      
              const allMessages = [...messagesSent, ...messagesReceived];
      
              const filteredMessages = allMessages.filter(
                message => (message.user_id === pal.id.toString() && message.receiver_id === user.id.toString()) || 
                           (message.user_id === user.id.toString() && message.receiver_id === pal.id.toString())
              );
      
              // Sort messages by date (assuming 'created_at' field is the date field)
              filteredMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      
              setMessages(filteredMessages);
            } else if (pal.chat === "group") {
              const groupId = pal.id; // You need to get the groupId from the "pal" object, replace "groupId" with the correct property.
      
              const groupMessagesResponse = await axios.get(`https://api.chulofilmhouse.co.ke/api/groupz/${pal.id}/messages`, {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
      
              const groupMessages = groupMessagesResponse.data;
      
              // Sort group messages by date (assuming 'created_at' field is the date field)
              groupMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      
              setMessages(groupMessages);
            }
          } catch (error) {
            console.error(error);
          }
        };
      
        fetchMessages();
        
      }, [messages,pal.chat, pal.id, user.id]);
      

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const dayOfWeek = date.toLocaleString('default', { weekday: 'short' });
        return `${hours}:${minutes} ${dayOfWeek}, ${day} ${month}`;
      };

      const handleInputChange = (e) => {
        e.preventDefault()

        setSendMessage(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
        console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKK");
      console.log(sendMessage);
      console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKK");
      }

    const sendChatMessage=async (e)=>{
        e.preventDefault()

        var chatData = new FormData()
        chatData.append("message", sendMessage.content)
        chatData.append("content", sendMessage.content)
        chatData.append("receiver_id", pal.id)
        try {
            var response
       if(pal.chat === "private"){
             response = await fetch(`https://api.chulofilmhouse.co.ke/api/messages`, {
              method: 'POST',
              headers: {
              //   'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: chatData,
            });
        }else{
             response = await fetch(`https://api.chulofilmhouse.co.ke/api/groupz/${pal.id}/send`, {
              method: 'POST',
              headers: {
              //   'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: chatData,
            });
        }
            if (response.ok) {
              // Handle successful post
              const resData = await response.json();
              console.log(resData);
              e.target.value = ""
              setSendMessage({
                content:""
              }
              );
            } else {
              const errorData = await response.json();
              console.error('Post failure:', errorData);
              // Handle post failure, such as showing an error message to the user
            }
          } catch (error) {
            console.error('Posting failed:', error);
            // Handle post failure, such as showing an error message to the user
          }
    }

    //location
    let location = useLocation();
    // console.log(document);
    
    return(
        <>
         <Tab.Pane eventKey="six" className={`fade ${show === 'six' ? 'show' : ''}`} id="chatbox6" role="tabpanel">
                                                    <div className="chat-head">
                                                        <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="sidebar-toggle">
                                                                    <i className="ri-menu-3-line"></i>
                                                                </div>
                                                                <div className="avatar chat-user-profile m-0 me-3">
                                                                  <Link className={`${location.pathname === '/' ? 'active' : ''} nav-link `} aria-current="page" to="/">
                                                                <i className="ri-home-4-fill me-3" style={{fontSize:"25px"}}></i>
                                                                </Link>
                                                                  {pal.chat === "private"?  <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${pal.profile_picture}`} alt="" className="avatar-40 " 
                                                                  // onClick={() => 
                                                                    //     setShow2('true')}
                                                                    />
                                                                  :  <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${pal.group_image}`} alt="" className="avatar-40 " 
    
                                                                    // onClick={() => 
                                                                    //     setShow2('true')}
                                                                        />}
                                                                    {/* <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span> */}
                                                                </div>
                                                                <h5 className="mb-0">{pal.name}</h5>
                                                            </div>
                                                            <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                                <div className="user-profile ">
                                                                <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" 
                                                                // onClick={() => setShow2('false')}
                                                                >close</i></Button>
                                                                    <div className="user mb-4 text-center">
                                                                        <Link className="avatar m-0" to="">
                                                                            <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${pal.profile_picture}`} alt=""/>
                                                                        </Link>
                                                                        <div className="user-name mt-4">
                                                                            <h4>Pauhl Molive</h4>
                                                                        </div>
                                                                        <div className="user-desc">
                                                                            <p>Cape Town, RSA</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="chatuser-detail text-left mt-4">
                                                                        <Row>
                                                                            <Col md="6"  className="col-6 title">Bni Name:</Col>
                                                                            <Col md="6"  className="col-6 text-right">Pau</Col>
                                                                        </Row>
                                                                        <hr/>
                                                                        <Row>
                                                                            <Col md="6"  className="col-6 title">Tel:</Col>
                                                                            <Col md="6"  className="col-6 text-right">072 143 9920</Col>
                                                                        </Row>
                                                                        <hr/>
                                                                        <Row>
                                                                            <Col md="6"  className="col-6 title">Date Of Birth:</Col>
                                                                            <Col md="6"  className="col-6 text-right">July 12, 1989</Col>
                                                                        </Row>
                                                                        <hr/>
                                                                        <Row>
                                                                            <Col md="6"  className="col-6 title">Gender:</Col>
                                                                            <Col md="6"  className="col-6 text-right">Male</Col>
                                                                        </Row>
                                                                        <hr/>
                                                                        <Row>
                                                                            <Col md="6"  className="col-6 title">Language:</Col>
                                                                            <Col md="6"  className="col-6 text-right">Engliah</Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="chat-header-icons d-flex">
                                                                {/* <Link to="#" className="chat-icon-phone  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">phone</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-video  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">videocam</i>
                                                                </Link>
                                                                <Link to="#" className="chat-icon-delete  bg-soft-primary d-flex justify-content-center align-items-center">
                                                                    <i className="material-symbols-outlined md-18">delete</i>
                                                                </Link>
                                                                <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown> */}
                                                            </div>
                                                        </header>
                                                    </div>
                                                    <div className="chat-content scroller">
                                                        {/* Start Full Chat */}
                                                       { !messages ?
                  <div></div>
               :
               messages.map((chat, index) => {
                // console.log(chat);
                                             return ( 
                                                      chat.user_id === user.id.toString() ?  <div className="chat d-flex other-user">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${user.profile_picture}`} alt="" className="avatar-35 "/>
                                                                </Link>
                                                                <span className="chat-time mt-1">{formatDate(chat.created_at)}</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    {pal.chat === "private"?
                                                                    <p>{chat.message}</p>:
                                                                    <p>{chat.content}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <Link className="avatar m-0" to="">
                                                                    <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${pal.profile_picture}`} alt="" className="avatar-35 "/>
                                                                </Link>
                                                                <span className="chat-time mt-1">{formatDate(chat.created_at)}</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                {pal.chat === "private"?
                                                                    <p>{chat.message}</p>:
                                                                    <p>{chat.content}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                             )})}
                                                        {/* End Full Chat */}
                                                        
                                                        
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <Form className="d-flex align-items-center" action="#">
                                                            <div className="chat-attagement d-flex">
                                                                {/* <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                                <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link> */}
                                                            </div>
                                                            <Form.Control type="text" className="form-control me-3" name="content" onChange={handleInputChange} value={sendMessage.content}  placeholder="Type your message"/>
                                                            <Button type="button" variant="primary d-flex align-items-center" onClick={sendChatMessage}><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                                        </Form>
                                                    </div>
                                                </Tab.Pane>
         </>
    )
}
export default ChatPane;    