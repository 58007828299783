import React from "react";
import ChatLayout from "../layouts/dashboard/chat-layout";
// import Default from "../layouts/dashboard/default";
import Chat from "../views/dashboard/app/chat";
// import Chat_PT from "../views/dashboard/app/pt_chat";

export const ChatRouter = [
  {
    path: "chat",
    // element: <ChatLayout />,
    element: <ChatLayout />,
    children: [
      {
        path: "index",
        element: <Chat />,
      },
    ],
  },
];
