import React, {useState, useRef, useEffect} from 'react'
import {Row,Col,Container,Dropdown,Tooltip,OverlayTrigger, Modal, Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
// import FadeInOut from './FadeInOut'

import FileDownloadButton from './fileDownload'

// img
import pageimg1 from '../../../assets/images/page-img/43.png'
import pageimg2 from '../../../assets/images/page-img/44.png'
import pageimg3 from '../../../assets/images/page-img/45.png'
import pageimg4 from '../../../assets/images/page-img/46.png'
import pageimg5 from '../../../assets/images/page-img/47.png'

import img1 from '../../../assets/images/page-img/47.png'

import axios from 'axios';

const File = () => {
    const user = JSON.parse(Cookies.get("user")).user
    const token = JSON.parse(Cookies.get("user")).token

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const hiddenFileInput = useRef(null);
    const [loading, setLoading] = useState(false);

    const [archiveFile, setArchiveFile] = useState();
    const [archiveMedia, setArchiveMedia] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [trigger, setTrigger] = useState(false);

    const [formData, setFormData] = useState({
        fileName: "",
        fileCategory: "",
        fileDescription: "",
        file: null
      });

      useEffect(() => {
        const token = JSON.parse(Cookies.get("user")).token
    axios.get('https://api.chulofilmhouse.co.ke/api/archive', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          const json = response.data;
          setArchiveFile(json);
        })
        .catch(error => console.error(error));
    }, [trigger]);

    const handleChange = (e) => {
        setFormData(prev => ({ 
          ...prev, 
          [e.target.name]: e.target.value 
        }))
      }

      function handleMediaChange(e) {
        console.log(e.target.files);
        // setArchiveFile(URL.createObjectURL(e.target.files[0]));
        setArchiveMedia(e.target.files[0]);
        
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
      };

      const validateForm = (formValues) => {
        if ( !formValues.fileName ||!formValues.fileDescription ||!formValues.fileCategory ||!archiveMedia ) {
          setErrMessage('fields cannot be empty');
          return false;
        }
        return true;
      }

      const addArchive = async () => {
        // setLoading(true)
        console.log(formData.fileName);
        console.log(formData.fileDescription);
        console.log(formData.fileCategory);
        console.log(archiveMedia);

       var postData = new FormData()
       postData.append("title", formData.fileName)
       postData.append("file_path", archiveMedia)
       postData.append("description", formData.fileDescription)
       postData.append("category", formData.fileCategory)
       for (let pair of postData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
        try {
      
          const response = await fetch('https://api.chulofilmhouse.co.ke/api/archive', {
            method: 'POST',
            headers: {
            //   'Content-Type': 'multipart/form-data',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: postData,
          });
      
          if (response.ok) {
            // Handle successful post
            const resData = await response.json();
            console.log(resData);
            setFormData({
              content: '',
              description: 'desc',
              user_image: null,
            });
            setArchiveMedia(null)
            setLoading(false)
            setShow(false)
            setTrigger(prevTrigger => !prevTrigger);
          } else {
            const errorData = await response.json();
            console.error('Post failure:', errorData);
            setErrMessage(errorData.message);
            // Handle post failure, such as showing an error message to the user
          }
        } catch (error) {
          console.error('Posting failed:', error);
          setErrMessage(error);
        //   setErrMessage(error.message);
          // Handle post failure, such as showing an error message to the user
        }
        
      };

      const submitArchive = (e) =>{
        e.preventDefault();
        setLoading(true)
    
        //= Validate Form
        if (!validateForm(formData)) return;
        // setLoading(true)
        //= Clear Error Message
        setErrMessage('');
    
        addArchive();
      }

      function getFileTypeByExtension(extension) {
        const fileTypes = {
          json: 'JSON File',
          txt: 'Text File',
          doc: 'Word Document',
          docx: 'Word Document (OpenXML)',
          pdf: 'PDF Document',
          png: 'PNG Image',
          jpg: 'JPEG Image',
          jpeg: 'JPEG Image',
          gif: 'GIF Image',
          mp3: 'MP3 Audio',
          mp4: 'MP4 Video',
          zip: 'ZIP Archive',
          rar: 'RAR Archive',
          tar: 'Tar Archive',
          gz: 'Gzip Archive',
          exe: 'Executable',
          apk: 'Android Package',
          csv: 'CSV File',
          xml: 'XML File',
          html: 'HTML File',
          css: 'CSS File',
          js: 'JavaScript File',
          py: 'Python File',
          java: 'Java File',
          cpp: 'C++ File',
          c: 'C File',
          h: 'Header File',
          otf: 'OpenType Font',
          ttf: 'TrueType Font',
          php: 'PHP File',
        };
      
        const fileType = fileTypes[extension.toLowerCase()];
        return fileType ? fileType : 'Unknown File Type';
      }

      const FileIconMap = {
        'JSON File': 'https://cdn-icons-png.flaticon.com/128/136/136525.png',
        'PDF Document': 'https://cdn-icons-png.flaticon.com/128/9496/9496432.png',
        'Word Document': 'https://image.flaticon.com/icons/svg/733/733594.svg',
      };

     
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleString('en-US', options);
      };

      const getDefaultFileIcon = () => 'https://cdn-icons-png.flaticon.com/128/2965/2965335.png';
    
   return(
       <>
           <div id='content-page' className='content-page'>           
            <Container>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Files</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                { user.role === "SuperAdmin" ?  
                                                <Link to="#" onClick={handleShow} className="chat-icon-delete btn bg-primary text-white">
                                                <i onClick={handleShow} className="material-symbols-outlined">
                                                                    add
                                                                </i>Add
                                                </Link>: <></>
}
                                            </div>
                                {/* <div className="card-header-toolbar d-flex align-items-center">
                                    <Dropdown>
                                        <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                            more_horiz
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="dropdownMenuButton5">
                                            <Dropdown.Item href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                            <Dropdown.Item href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                                
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    {/* <Row className="justify-content-between d-flex">
                                        <Col sm="12" md="6">
                                            <div id="user_list_datatable_info" className="dataTables_filter">
                                                <form className="me-3 position-relative">
                                                    <div className="form-group mb-0">
                                                        <input type="search" className="form-control" id="exampleInputSearch" placeholder="Search"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className="user-list-files d-flex justify-content-end">
                                                <Link to="#" className="chat-icon-phone btn bg-soft-primary">
                                                Print
                                                </Link>
                                                <Link to="#" className="chat-icon-video btn bg-soft-primary">
                                                Excel
                                                </Link>
                                                <Link to="#" className="chat-icon-delete btn bg-soft-primary">
                                                Pdf
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row> */}
                                    
                                    <table className="files-lists table table-striped mt-2">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">
                                                    <div className=" text-center">
                                                        <input type="checkbox" className="form-check-input"/>
                                                    </div>
                                                </th> */}
                                                <th scope="col"></th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Description</th>
                                                {/* <th scope="col">File Category</th> */}
                                                <th scope="col">Type</th>
                                                <th scope="col">Action</th>
                                                {/* <th scope="col">Author</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
               !archiveFile ?
                  <div>...</div>
               :
               Object.entries(archiveFile).map((tab, index) =>{ 
                const fileName = tab["1"].file_path.split('/').pop();
                const fileExtension = fileName ? fileName.split('.').pop() : null;
                const fileType = fileExtension ? getFileTypeByExtension(fileExtension) : "file"
                const formattedDate = formatDate(tab["1"].created_at);
                const getFileIcon = () => FileIconMap[fileType] || getDefaultFileIcon();
                return (
                // <FadeInOut key={index} 
                // show={true}
                // >
                <tr>
                                                {/* <td>
                                                    <div className=" text-center">
                                                        <input type="checkbox" className="form-check-input"/>
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <img className="rounded-circle img-fluid avatar-40 me-2" src={getFileIcon()} alt="" style={{objectFit: "contain"}}/>
                                                </td>
                                                <td>{tab["1"].title}</td>
                                                <td>
                                                {formattedDate}
                                                </td>
                                                <td>{tab["1"].description}</td>
                                                <td>
                                                {fileType}
                                                </td>
                                                <td>
                                                    <div className="flex align-items-center list-user-action">
                                                        
                                                        <FileDownloadButton fileURL={tab["1"].file_path} customFilename={tab["1"].title} />
                                { user.role === "SuperAdmin" ?  

                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                            <Link to="#">
                                                                <i className="material-symbols-outlined">
                                                                    delete
                                                                </i>
                                                            </Link>
                                                        </OverlayTrigger> : <></>}
                                                    </div>
                                                </td>
                                            </tr>
                 
               // </FadeInOut> 
              )})
            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               </Container>
               <Modal size="lg" className="fade mt-4" id="post-modal" onHide={handleClose} show={show} >
               <div className="mt-4"></div>
                                    <Modal.Header  className="d-flex justify-content-between mt-4">
                                        
                                    {errMessage}
                                        <Modal.Title id="post-modalLabel">Add File</Modal.Title>
                                        <Link to="#" className="lh-1" onClick={handleClose} >
                                            <span className="material-symbols-outlined">close</span>
                                        </Link>
                                        
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form className="mt-4">
                                    <Row className="justify-content-between d-flex">
                                    <Col sm="12" md="6">
                                    <Form.Group className="form-group">
                              <Form.Label>File Name</Form.Label>
                              <Form.Control type="text" className="mb-0" id="fileName" placeholder="Enter File Name" name="fileName" onChange={handleChange} value={formData.name}/>
                           </Form.Group>
                           </Col>
                           <Col sm="12" md="6">
                           <Form.Group className="form-group">
                              <Form.Label>Category</Form.Label>
                              <Form.Control type="text" className="mb-0" id="fileCategory" placeholder="Enter Category" name="fileCategory" onChange={handleChange} value={formData.email}/>
                           </Form.Group>
                           </Col>
                           <Col sm="12" md="12">
                           <Form.Group className="form-group">
                              <Form.Label>Description</Form.Label>
                              <Form.Control as="textarea" rows={3} className="mb-0" id="fileDescription" placeholder="File Description" name="fileDescription" onChange={handleChange} value={formData.description}/>
                           </Form.Group>
                           </Col>
                           <Col sm="12" md="6">                       
                            <Form.Control type="file" 
                                        ref={hiddenFileInput}
                                        accept="*"
                                        style={{ display: 'none' }} 
                                        onChange={handleMediaChange} />
                                            <Link to="#" className="btn btn-soft-primary" onClick={handleClick}>
                                                <img src={img1} alt="icon" className="img-fluid" style={{maxHeight:"24px"}}/> 
                                            </Link>
                                            </Col>
                           <Col sm="12" md="6">
                                            <button type="button" onClick={submitArchive} className="btn btn-success d-block w-80 mt-3 float-right">Submit</button>
                                            </Col>
                                            </Row>
                                        {loading ? 
                  <div className="mt-30">
                    {/* Load Spinner START */}
                    <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
                {/* Load Spinner END */}
                  </div>
                  : <></>}
                                    </Form>
                                    
                                    </Modal.Body>
                                </Modal>
            </div>   
        </>
   )
}
export default File