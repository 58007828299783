import React, { useState, useEffect, useRef } from 'react';
import styles from './scrollView.module.css'

const ScrollingView = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const scrollRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      if (!isHovered) {
        scrollElement.scrollTop = 0;
      }
    }
  }, [isHovered]);

  return (
    <div
      className={`${styles.rightSidebarMini} ${isHovered ? '' : styles.scroll}`}
      id="rightSidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={scrollRef}
    >
      {children}
    </div>
  );
};

export default ScrollingView;
