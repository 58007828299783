import { useEffect } from 'react';
import parallaxie from './parallaxie';
import {Link} from 'react-router-dom'
import {Row, Col, Container, Button, Dropdown, ButtonGroup, ToggleButton} from 'react-bootstrap'


const CallToAction = () => {
  useEffect(() => {
    parallaxie('.call-action.bg-img.parallaxie');
  }, []);

  return (
    <section className="section-padding bg-img parallaxie call-action" data-background="https://cdn.pixabay.com/photo/2014/08/25/17/17/offering-427297_640.jpg" data-overlay-dark="9">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="text-center">
              <h2 className="mb-5">Support AFROHUN</h2>
              <h5 className="mb-5">Make a Meaningful Donation</h5>
              <p>Your unwavering support, both through your generous contributions and in various other ways, has the power to create a magnificent transformation. We warmly invite you to explore the diverse avenues through which you can make a meaningful impact. Consider volunteering your invaluable time and skills, sharing your profound knowledge and expertise, donating essential materials or resources, or offering a sanctuary of inspiration within your working space. Additionally, your financial contributions can further bolster our programs, empowering generations to come and leaving an everlasting impact on our esteemed community. Embrace this opportunity to be a catalyst for change by joining us on this remarkable journey. Together, through your multifaceted support, we can achieve extraordinary feats and forge a path of greatness for all of us!</p>
              

              {/* <Button href="#" variant="primary" className="mb-1 ml-15 mt-20 butn butn-md text-light">
              <span className="text slide-up">Donate</span>
                <span className="text slide-down">Now</span>
                              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction