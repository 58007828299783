import React,{useState} from 'react';
import {Container, Row, Col, Card, Form, Button, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'

// img
import image1 from '../../../assets/images/page-img/img-success.png'

const FormWizardEngageUs = () => {
    const [show, AccountShow] = useState('A');
    return (
        <>
          <div id='content-page' className='content-page'>
            <Container>
                <Row>
                    <Col sm="12">
                        <Card className="card position-relative inner-page-bg bg-primary" style={{height: "150px"}}>
                            <div className="inner-page-title">
                                <h3 className="text-white">Engage Us Initiative</h3>
                                <p className="text-white">An avenue for you to define the agenda. Unleash your imagination as we tailor bespoke services to suit your aspirations. Whether it's empowering your community through specialized training or igniting progress through bespoke partnerships, simply take flight.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title"></h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md="3">
                                        <ul id="top-tabbar-vertical" className="p-0">
                                            <li className={` ${show === '' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''}  active step1`} id="personal">
                                                <Link to="#">
                                                    <i className="material-symbols-outlined bg-soft-primary text-primary">lock_open</i><span>Personal</span>
                                                </Link>
                                            </li>
                                            <li id="contact" className={` ${show === 'Account' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Image' ? 'active done' : ''} step2`}>
                                                <Link to="#">
                                                    <i className="material-symbols-outlined bg-soft-danger text-danger">person</i><span>Contact</span>
                                                </Link>
                                            </li>
                                            <li id="official" className={` ${show === 'Personal' ? 'active done' : ''} ${show === 'Image' ? 'active done' : ''} step3`}>
                                                <Link to="#">
                                                    <i className="material-symbols-outlined bg-soft-success text-success">photo_camera</i><span>Official</span>
                                                </Link>
                                            </li>
                                            <li id="payment" className={` ${show === 'Image' ? 'active done' : ''} step4`}>
                                                <Link to="#">
                                                    <i className="material-symbols-outlined bg-soft-warning text-warning">done</i><span>Engagement</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col md="9">
                                        <Form id="form-wizard3" className="text-start">
                                            <fieldset className={`${show === 'A' ? 'd-block' : 'd-none'}`}>
                                                <div className="form-card text-left">
                                                    <Row>
                                                        <div className="col-12">
                                                            <h3 className="mb-4">User Information:</h3>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>First Name: *</Form.Label>
                                                                <Form.Control type="text" id="fname" name="fname" placeholder="First Name" required="required" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Last Name: *</Form.Label>
                                                                <Form.Control type="text" id="lname" name="lname" placeholder="Last Name" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Gender: *</Form.Label>
                                                                <Form.Check className="form-check">
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        <Form.Check.Input type="radio" className="form-check-input" name="customRadio" id="customRadio1"/>
                                                                        <Form.Check.Label> Male</Form.Check.Label>
                                                                    </Form.Check>
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        <Form.Check.Input type="radio" className="form-check-input" name="customRadio" id="customRadio2"/>
                                                                        <Form.Check.Label> Female</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Date Of Birth: *</Form.Label>
                                                                <Form.Control type="date" id="dob" name="dob" />
                                                            </Form.Group>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                                <Button id="submit" name="next" className="btn-primary next action-button float-end" value="Next" onClick={() => AccountShow('Account')}>Next</Button>
                                            </fieldset>
                                            <fieldset className={`${show === 'Account' ? 'd-block' : 'd-none'}`}>
                                                <div className="form-card text-left">
                                                    <Row>
                                                        <div className="col-12">
                                                            <h3 className="mb-4">Contact Information:</h3>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Email Id: *</Form.Label>
                                                                <Form.Control type="email" id="email" name="email" placeholder="Email Id" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Contact Number: *</Form.Label>
                                                                <Form.Control type="text" id="ccno" name="ccno" placeholder="Contact Number" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>City: *</Form.Label>
                                                                <Form.Control type="text" id="city" name="city" placeholder="City." />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>State: *</Form.Label>
                                                                <Form.Control type="text" id="state" name="state" placeholder="State." />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Button varint="primary" name="next" className="next action-button float-end" value="Next" onClick={() => AccountShow('Personal')}>Next</Button>
                                                <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('A')}>Previous</Button>
                                            </fieldset>
                                            <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                                <div className="form-card text-left">
                                                    <Row>
                                                        <div className="col-12">
                                                        <h3 className="mb-4">Official Information:</h3>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Organisation/Institution: *</Form.Label>
                                                                <Form.Control type="text" className="form-control" id="empid" name="empid" placeholder="Company/Organisation/University" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Designation/Discipline: *</Form.Label>
                                                                <Form.Control type="text" className="form-control" id="desg" name="desg" placeholder="Your designation or discipline of study." />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Department Name: *</Form.Label>
                                                                <Form.Control type="text" className="form-control" id="accname" name="accname" placeholder="Department Name." />
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Working Hour: *</Form.Label>
                                                                <Form.Control type="text" className="form-control" id="workhour" name="workhour" placeholder="Working Hour." />
                                                            </Form.Group>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                                <Button variant="primary" name="next" className="next action-button float-end" value="Submit" onClick={() => AccountShow('Image')}>Next</Button>
                                                <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('Account')}>Previous</Button>
                                            </fieldset>
                                            <fieldset className={`${show === 'Image' ? 'd-block' : 'd-none'}`}>
                                                <div className="form-card text-left">
                                                    <Row>
                                                        <div className="col-12">
                                                            <h3 className="mb-4 text-left">Engagement:</h3>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                        <Form.Label>Engagement Type</Form.Label>
                                                        <Form.Control
                                                                            as="select"
                                                                            className="mb-0"
                                                                            name="type"
                                                                            //  onChange={handleChange}
                                                                            //  value={formData.type}
                                                                        >
                                                                            <option value="">Select Engagement Type</option>
                                                                            <option >Trainig</option>
                                                                            <option >Seminar</option>
                                                                            <option >Camps</option>
                                                                            
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                                        </Col>
                                                        {/* <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Account No: *</Form.Label>
                                                                <Form.Control type="text" id="accno" name="accno" placeholder="Account No." />
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>Account Holder Name: *</Form.Label>
                                                                <Form.Control as="textarea" rows={3} className="mb-0" id="fileDescription" placeholder="Brief Description" name="fileDescription" />
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label>IFSC Code: *</Form.Label>
                                                                <Form.Control type="text" id="ifsc" name="ifsc" placeholder="IFSC Code." />
                                                            </Form.Group>
                                                        </Col>   */}
                                                    </Row>
                                                </div>
                                                <Button variant="primary" className="action-button float-end" href="#" onClick={() => AccountShow('Image2')}>Submit</Button>
                                                <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('Personal')}>Previous</Button>
                                            </fieldset> 
                                            <fieldset className={`${show === 'Image2' ? 'd-block' : 'd-none'}`}>
                                                <div className="form-card">
                                                    <Row>
                                                        <div className="col-7">
                                                            <h3 className="mb-4 text-left">Finish:</h3>
                                                        </div>
                                                        <div className="col-5">
                                                            <h2 className="steps">Step 4 - 4</h2>
                                                        </div>
                                                    </Row>
                                                    <br/><br/>
                                                    <h2 className="text-success text-center"><strong>SUCCESS !</strong></h2>
                                                    <br/>
                                                    <Row className="justify-content-center">
                                                        <div className="col-3"> 
                                                            <Image src={image1} className="img-fluid" alt="fit-image"/>
                                                        </div>
                                                    </Row>
                                                    <br/><br/>
                                                    <Row className="justify-content-center">
                                                        <div className="col-7 text-center">
                                                            <h5 className="purple-text text-center">You have successfully submited your engagement request</h5>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </fieldset>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default FormWizardEngageUs