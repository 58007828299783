import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

export default function TwitterFeed() {
  

  return (
    <div>
      {/* <h1>Recent Tweets</h1> */}
      <TwitterTimelineEmbed
  sourceType="profile"
  screenName="AFROHUN_Kenya"
  options={{height: 420}}
/>
    </div>
  );
}
