import React from 'react';
import {Row,Col,Container,Dropdown,Tooltip,OverlayTrigger, Modal, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'


const FileDownloadButton = ({ fileURL, customFilename }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', customFilename); // Set the custom filename here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
                                                            <Link onClick={handleDownload} to="#" >
                                                                <i className="material-symbols-outlined">
                                                                    file_download
                                                                </i>
                                                            </Link>
                                                        </OverlayTrigger>
    // <button onClick={handleDownload}>Download File</button>
  );
};

export default FileDownloadButton;
