import { Helmet } from "react-helmet";

const MainLayout = ({ children, defaultTheme, defaultLogoTheme }) => {


  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/plugins.css" />
        <link rel="stylesheet" href="/css/style.css" />
      </Helmet>
      {children}
    </>
  );
};

export default MainLayout;
