import React from "react";

import Intro from "../views/dashboard/auth/intro";

export const IntroRouter = [
  {
    path: "/",
    element: <Intro />,
  }
  
];
