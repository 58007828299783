import React, {useState, useRef, useEffect}  from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown,Modal} from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import axios from 'axios';

//img
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
// import page100 from '../../../assets/images/page-img/100.jpg'
import ChatPane from './chat_pane'

import Cookies from 'js-cookie';

const Chat=()=>{
    const [show, setShow] = useState('')
    const [show1, setShow1] = useState('')
    const [show2, setShow2] = useState('')
    const [modalSHow, setModalSHow] = useState(false);
    const handleClose = () => setModalSHow(false);
    const handleShow = () => setModalSHow(true);
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }

    const user = JSON.parse(Cookies.get("user")).user

    const history = useNavigate();
    const location = useLocation();

    const [recentChats, setRecentChats] = useState(true);
    const [recentGroups, setRecentGroups] = useState(false);
    const [people, setPeople] = useState(false);
    const [groups, setGroups] = useState(false);

    const [outgoing, setOutgoing] = useState([]);
    const [incoming, setIncoming] = useState([]);
    const [users, setUsers] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const handleListChange = (e) => {
        console.log(e.target.value);
       if( e.target.value === "recentChats"){
        setRecentChats(true)
        setRecentGroups(false)
        setPeople(false)
        setGroups(false)
    //    }else if( e.target.value === "recentGroups"){
    //     setRecentChats(false)
    //     setRecentGroups(true)
    //     setPeople(false)
    //     setGroups(false)
       }else if( e.target.value === "people"){
        setRecentChats(false)
        setRecentGroups(false)
        setPeople(true)
        setGroups(false)
       }else if( e.target.value === "groups"){
        setRecentChats(false)
        setRecentGroups(false)
        setPeople(false)
        setGroups(true)
       }else{
        setRecentChats(true)
        setRecentGroups(false)
        setPeople(false)
        setGroups(false)
       }
        
      }
      
useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
fetch('https://api.chulofilmhouse.co.ke/api/user/groups', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
  .then(json =>{
      setGroupList(json);
      console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
          console.log(json);
          console.log(token);
          console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF")
    })
    .catch(error => console.error(error));
}, []);
useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
fetch('https://api.chulofilmhouse.co.ke/api/users', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
  .then(json =>{
      setUsers(json);
    })
    .catch(error => console.error(error));
}, []);
useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
fetch('https://api.chulofilmhouse.co.ke/api/messages', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
  .then(json =>{
      setOutgoing(json);
    })
    .catch(error => console.error(error));
}, []);
useEffect(() => {
    const token = JSON.parse(Cookies.get("user")).token
fetch('https://api.chulofilmhouse.co.ke/api/messages/1', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json())
  .then(json =>{
      setIncoming(json);
    })
    .catch(error => console.error(error));
}, []);

useEffect(() => {
 setMessages([...incoming, ...outgoing])
 const uniqueIds = [
     ...new Set(
       messages.flatMap(item => [item.user_id, item.receiver_id])
     )
   ];
   // Remove "8" from the uniqueIds array
   const filteredIds = uniqueIds.filter(id => id !== user.id.toString());
   
//    console.log(user.id);
//    console.log(filteredIds);

//    setChats(filteredIds)
const token = JSON.parse(Cookies.get("user")).token
const fetchChatPals = async () => {
    try {
      const requests = filteredIds.map(user_id =>
        axios.get(`https://api.chulofilmhouse.co.ke/api/users/${user_id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
      );

      const responses = await Promise.all(requests);
      const chatPals = responses.map(response => response.data);
      setChats(chatPals);
    } catch (error) {
      console.error(error);
    }
  };

  fetchChatPals();

console.log(chats);
}, [incoming, outgoing]);



useEffect(() => {
    
    
    if(selectedUser.length < 1){
        if(!location.state){}else{
            
            setShow('six');
            console.log("UUUUUUUUUUUUUUUUUUUUUUU");
            console.log(typeof location.state);
            console.log("UUUUUUUUUUUUUUUUUUUUUUU");
            setSelectedUser(location.state);
            // history(`/chat/${user.id}`); 
        }
    }else{
        setSelectedUser(selectedUser);
        // history(`/chat/${user.id}`); 
    }
}, [user, selectedUser, location.state]);

const handleChatSelection = (user) => {
   // Navigate to chat page with selected user's ID in the URL
  };

    
    return(
        <>
            <Tab.Container id="left-tabs-example"  defaultActiveKey="start">                        
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Body className="chat-page p-0">
                                <div className="chat-data-block">
                                    <Row>
                                    <Col lg={9} className=" chat-data p-0 chat-data-right">
                                            <Tab.Content>
                                            <Tab.Pane eventKey="start" className="tab-pane fade show" id="default-block" role="tabpanel">
                                                    <div className="chat-start">
                                                        <span className="iq-start-icon text-primary"><i className="material-symbols-outlined md-42">sms</i></span>
                                                        {/* <Button id="chat-start" onClick={(e)=>{
                                                            e.preventDefault()
                                                            setRecentChats(true)}} bsPrefix="btn bg-white mt-3">Start
                                                        Conversation!</Button> */}
                                                    </div>
                                                </Tab.Pane>
                                                <ChatPane show={show === 'six' ? 'show' : '' } pal={selectedUser}></ChatPane>
                                                
                                            </Tab.Content>
                                        </Col>
                                        <Col lg="3" className="chat-data-left scroller">
                                            <div className="chat-search pt-3 ps-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="chat-profile me-3">
                                                    <Link className="avatar m-0" href="../../" to="../../"> 
                                                    <img  loading="lazy"  src={`https://api.chulofilmhouse.co.ke/${user.profile_picture}`} alt="chat-user" className="avatar-60" 
                                                        // onClick={() => setShow1('true')}
                                                        />
                                                        </Link>
                                                    </div>
                                                    <div className="chat-caption">
                                                        <h5 className="mb-0">{user.name}</h5>
                                                        {/* <p className="m-0">Web Designer</p> */}
                                                    </div>
                                                    </div>
                                                    <Form.Group className="form-group mt-3">
                                            <Form.Control
                                             as="select"
                                             className="mb-0"
                                             name="listType"
                                             onChange={handleListChange}
                                            //  value={formData.type}
                                          >
                                             <option value="">Select Chats</option>
                                             <option value="recentChats" name="recentChats" >Recent Chats</option>
                                             {/* <option value="recentGroups" name="recentGroups" >Recent Groups</option> */}
                                             <option value="people" name="people">People</option>
                                             <option value="groups" name="groups" >Groups</option>
                                             
                                          </Form.Control>
                                            </Form.Group>
                                                
                                                <div id="user-detail-popup" className={`scroller ${show1 === 'true' ? 'show' : '' }`}>
                                                    <div className="user-profile">
                                                        <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow1('false')}>close</i></Button>
                                                        <div className="user text-center mb-4">
                                                            <Link className="avatar m-0" to="">
                                                                <img loading="lazy" src={user1} alt="avatar"/>
                                                            </Link>
                                                            <div className="user-name mt-4">
                                                                <h4 className="text-center">Bni Jordan</h4>
                                                            </div>
                                                            <div className="user-desc">
                                                                <p className="text-center">Web Designer</p>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="user-detail text-left mt-4 ps-4 pe-4">
                                                            <h5 className="mt-4 mb-4">About</h5>
                                                            <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                                            <h5 className="mt-3 mb-3">Status</h5>
                                                            <ul className="user-status p-0">
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-success pe-1"></i><span>Online</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-warning pe-1"></i><span>Away</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-danger pe-1"></i><span>Do Not Disturb</span></li>
                                                                <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-light pe-1"></i><span>Offline</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <hr/> */}
                                                {/* <div className="chat-searchbar mt-4">
                                                    <Form.Group className="form-group chat-search-data m-0">
                                                        <input type="text" className="form-control round" id="chat-search" placeholder="Search"/>
                                                            <i className="material-symbols-outlined">
                                                                search
                                                            </i>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="chat-sidebar-channel scroller mt-4 ps-3">
                                            {/* <h5 >Public Channels</h5>
                                            <Nav as="ul" variant="pills" className="iq-chat-ui nav flex-column">
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="first" onClick={() => setShow('first')}    href="#chatbox1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img loading="lazy" src={user5} alt="" className="avatar-50 "/>
                                                                <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
                                                            </div>
                                                            <div className="chat-sidebar-name">
                                                                <h6 className="mb-0">Team Discussions</h6>
                                                                <span>Lorem Ipsum is</span>
                                                            </div>
                                                            <div className="chat-meta float-right text-center mt-2 me-1">
                                                                <div className="chat-msg-counter bg-primary text-white">20</div>
                                                                <span className="text-nowrap">05 min</span>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <li>
                                                    <Nav.Link  eventKey="second"  onClick={() => setShow('second')}    href="#chatbox2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img loading="lazy" src={user6} alt="" className="avatar-50 "/>
                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                            </div>
                                                            <div className="chat-sidebar-name">
                                                                <h6 className="mb-0">Announcement</h6>
                                                                <span>This Sunday we</span>
                                                            </div>
                                                            <div className="chat-meta float-right text-center mt-2 me-1">
                                                                <div className="chat-msg-counter bg-primary text-white">10</div>
                                                                <span className="text-nowrap">10 min</span>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </li>
                                            </Nav>
                                            <h5 className="mt-3">Private Channels</h5>
                                            <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                                <li>
                                                    <Nav.Link  eventKey="third" onClick={() => setShow('third')} href="#chatbox3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img loading="lazy" src={user7} alt="" className="avatar-50 "/>
                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                                            </div>
                                                            <div className="chat-sidebar-name">
                                                                <h6 className="mb-0">Designer</h6>
                                                                <span>There are many </span>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </li>
                                                <li>
                                                    <Nav.Link  eventKey="forth" onClick={() => setShow('forth')} href="#chatbox4">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img loading="lazy" src={user8} alt="" className="avatar-50 "/>
                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                            </div>
                                                            <div className="chat-sidebar-name">
                                                                <h6 className="mb-0">Developer</h6>
                                                                <span>passages of Lorem</span>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </li>
                                                <li>
                                                    <Nav.Link  eventKey="five" onClick={() => setShow('five')} href="#chatbox5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img loading="lazy" src={user9} alt="" className="avatar-50 "/>
                                                                <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-info"></i></span>
                                                            </div>
                                                            <div className="chat-sidebar-name">
                                                                <h6 className="mb-0">Testing Team</h6>
                                                                <span>Lorem Ipsum used</span>
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </li>
                                            </Nav>
                                            <h5 className="mt-3">Direct Message</h5> */}
                                            {recentChats ?
                                            <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                               
                                            {
                                                            !chats ?
                                                                <li></li>
                                                            :
                                                            chats.map((buddy, index) => {
                                                                // console.log(buddy);
                                                                                            return (   <li key={index}>
                                                                                                    <Nav.Link  eventKey="six" onClick={() =>{ 
                                                                                                        setShow('six')
                                                                                                        console.log(typeof buddy);
                                                                                                setSelectedUser(buddy)}} href="#">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <div className="avatar me-2">
                                                                                                                <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${buddy.profile_picture}`} alt="" className="avatar-35 "/>
                                                                                                                {/* <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span> */}
                                                                                                            </div>
                                                                                                            <div className="chat-sidebar-name">
                                                                                                                <h6 className="mb-0">{buddy.name}</h6>
                                                                                                                {/* <span>translation by</span> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Nav.Link>
                                                                                                </li>);
                                                })}
                                            </Nav>: <></>}
                                            {people ?
                                            <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                               
                                            {
                                                            !users ?
                                                                <li></li>
                                                            :
                                                            users.map((buddy, index) => {
                                                                // console.log(buddy);
                                                                                            return (   <li key={index}>
                                                                                                    <Nav.Link  eventKey="six" onClick={() =>{ 
                                                                                                        setShow('six')
                                                                                                        console.log(typeof buddy);
                                                                                                setSelectedUser(buddy)}} href="#">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <div className="avatar me-2">
                                                                                                                <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${buddy.profile_picture}`} alt="" className="avatar-35 "/>
                                                                                                                {/* <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span> */}
                                                                                                            </div>
                                                                                                            <div className="chat-sidebar-name">
                                                                                                                <h6 className="mb-0">{buddy.name}</h6>
                                                                                                                {/* <span>translation by</span> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Nav.Link>
                                                                                                </li>);
                                                })}
                                            </Nav>: <></>}
                                            {recentGroups ?
                                            <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                               
                                            {
                                                            !chats ?
                                                                <li></li>
                                                            :
                                                            chats.map((buddy, index) => {
                                                                // console.log(buddy);
                                                                                            return (   <li key={index}>
                                                                                                    <Nav.Link  eventKey="six" onClick={() =>{ 
                                                                                                        setShow('six')
                                                                                                        console.log(typeof buddy);
                                                                                                setSelectedUser(buddy)}} href="#">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <div className="avatar me-2">
                                                                                                                <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${buddy.profile_picture}`} alt="" className="avatar-35 "/>
                                                                                                                {/* <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span> */}
                                                                                                            </div>
                                                                                                            <div className="chat-sidebar-name">
                                                                                                                <h6 className="mb-0">{buddy.name}</h6>
                                                                                                                {/* <span>translation by</span> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Nav.Link>
                                                                                                </li>);
                                                })}
                                            </Nav>: <></>}
                                            {groups ?
                                            <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                               
                                            {
                                                            !groupList ?
                                                                <li></li>
                                                            :
                                                            groupList.map((buddy, index) => {
                                                                // console.log(buddy);
                                                                                            return (   <li key={index}>
                                                                                                    <Nav.Link  eventKey="six" onClick={() =>{ 
                                                                                                        setShow('six')
                                                                                                        console.log(typeof buddy);
                                                                                                setSelectedUser(buddy)}} href="#">
                                                                                                        <div className="d-flex align-items-center">
                                                                                                            <div className="avatar me-2">
                                                                                                                <img loading="lazy" src={`https://api.chulofilmhouse.co.ke/${buddy.group_image}`} alt="" className="avatar-35 "/>
                                                                                                                {/* <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span> */}
                                                                                                            </div>
                                                                                                            <div className="chat-sidebar-name">
                                                                                                                <h6 className="mb-0">{buddy.name}</h6>
                                                                                                                {/* <span>translation by</span> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Nav.Link>
                                                                                                </li>);
                                                })}
                                            </Nav>: <></>}
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                </Tab.Container>

        </>
    )
}
export default Chat;    