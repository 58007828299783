import React, { useState } from 'react';

const ReadMoreText = ({ text, maxCharCount }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      {isTruncated ? text.slice(0, maxCharCount) : text}
      {text.length > maxCharCount && (
        <span onClick={toggleTruncate} style={{ cursor: 'pointer', color: 'blue' }}>
          {isTruncated ? '... Read more' : ' Read less'}
        </span>
      )}
    </div>
  );
};

export default ReadMoreText;
