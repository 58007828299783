import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";
import removeSlashFromPagination from "../removeSlashFromPagination";
import Split from "../Split";
import headerSlides from './header1.json';
import {Row, Col, Container, Button, Dropdown, ButtonGroup, ToggleButton, Image} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie';

import "swiper/css/pagination";
import "swiper/css/navigation";

import logo from '../../../../assets/images/logo.png'
import usaid from '../../../../assets/images/usaid.png'

import './style.css';

SwiperCore.use([Navigation, Pagination, Parallax]);

const Header = () => {
  const [load, setLoad] = useState(true);

  let history =useNavigate()

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);

  const token = !Cookies.get("user") ? null : JSON.parse(Cookies.get("user")).token

  const authClickIn = ()=>{
    if(token === null){
      history('/auth/sign-in');
    }else{
      window.location.reload();
    }
  }

  const authClickUp = ()=>{
    if(token === null){
      history('/auth/sign-up');
    }else{
      window.location.reload();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    });

    setTimeout(() => {
      removeSlashFromPagination();
    });
  }, []);

  const stagnantButtonStyle = {
    position: 'absolute',
    top: '75%',
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Center vertically and horizontally
    zIndex: 100,
  };

  const coloredDivStyle = {
    backgroundColor: 'rgba(10, 15, 40, 0.75)', // Color with transparency
    borderRadius: '12px', // Curved corners
    padding: '12px',
    
  };

  const imageContainerStyleLeft = {
    backgroundColor: 'rgba(200, 225, 250, 0.05)', // Color with transparency
    borderRadius: '10px', // Curved corners
    padding: '10px', // Add some padding to create spacing between the image and the container
    display: 'inline-block',
    width:"180px", height:"90px",
    position:"fixed", top:"5px", left:"20px" ,// Maintain inline layout
  };

  const imageContainerStyleRight = {
    backgroundColor: 'rgba(200, 225, 250, 0.05)', // Color with transparency
    borderRadius: '10px', // Curved corners
    padding: '10px', // Add some padding to create spacing between the image and the container
    display: 'inline-block',
    width:"180px", height:"90px",
    position:"fixed", top:"5px", right:"20px" ,// Maintain inline layout
  };

  const imageStyle = {
    maxWidth: '100%', // Ensure the image fits within the container
    display: 'block', // Remove any extra spacing caused by inline elements
    borderRadius: '8px', // Adjust the image's border radius to fit within the container's border radius
  };
  

  return (
    <header className="slider slider-prlx o-hidden">
      
      
      {
        !load ?
          (
            <>
            <div style={{ position: 'relative' }}>
            <Swiper
              className="parallax-slider"
              speed={1000}
              parallax={true}
              slidesPerView={1}
              loop={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              pagination={{
                type: "fraction",
                clickable: true,
                el: paginationRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.params.pagination.el = paginationRef.current;
              }}
            //   onSwiper={(swiper) => {
            //     setTimeout(() => {
            //       for (var i = 0; i < swiper.slides?.length; i++) swiper.slides[i].childNodes[0].setAttribute("data-swiper-parallax", 0.75 * swiper.width);

            //       swiper.params.navigation.prevEl = navigationPrevRef.current;
            //       swiper.params.navigation.nextEl = navigationNextRef.current;

            //       swiper.params.pagination.el = paginationRef.current;

            //       // swiper?.navigation.destroy();
            //       // swiper?.navigation.init(); 
            //       // swiper?.navigation.update();

            //       // swiper?.pagination.destroy();
            //       // swiper?.pagination.init();
            //       // swiper?.pagination.update();
            //     });
            //   }
            // }
            >
              {
                headerSlides.map(slide => (
                  <SwiperSlide key={slide.id}>
                    <div className="bg-img valign" style={{ backgroundImage: `url('${slide.bg}')` }} >
                      <Row>
                        <Col md="6">
                        
                    <div className="" style={imageContainerStyleLeft}>
                    <Image src={usaid} className="usaid-logo-image img-fluid" alt="logo" style={slide.id === 2 ? 
                    {width:"auto", height:"160px", position:"fixed", top:"-35px", left:"10px"} : {width:"auto", height:"160px", position:"fixed", top:"-35px", left:"200px"}}/>
      </div>
      </Col>
                        <Col md="6">
                        
                    <div className="ah-logo" style={imageContainerStyleRight}>
      <Image src={logo} className="img-fluid mt-2 mb-2" alt="logo" style={slide.id === 2 ? {width:"auto", height:"60px", position:"fixed", top:"5px", left:"450px"}:{width:"auto", height:"60px", position:"fixed", top:"15px", right:"250px"}}/>
      </div>
      </Col>
                      </Row>
                      <div className="welcome-message container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8 col-md-10">
                            <div className="caption text-center mt-5" style={coloredDivStyle}>
                              <Split>
                                <h2 className="fw-800" data-splitting style={{color:"#fff"}}>{ slide.title }</h2>
                              </Split>
                              <div className="row justify-content-center" style={{marginTop:"-30px"}}>
                                <div className="col-lg-10">
                                  <p className="fz-18" style={{color:"#fff"}}>{ slide.details }</p>
                                </div>
                              </div>
                              {/* <div className="mt-30">
                              <Button onClick={authClickIn}  variant="primary" className="mb-1 mr-15 butn butn-md text-light">
                              <span className="text slide-up">{ slide.btn.text1 }</span>
                                  <span className="text slide-down">{ slide.btn.text1 }</span>
                              </Button>
                              <Button onClick={authClickUp} variant="primary" className="mb-1 ml-15 gr-purple-bg butn butn-md text-light">
                              <span className="text slide-up">{ slide.btn.text2 }</span>
                                  <span className="text slide-down">{ slide.btn.text2 }</span>
                              </Button>
                               
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
            </div>
            <div className="auth-grp" style={stagnantButtonStyle} >
                              <Button  onClick={authClickIn}  variant="primary" className="auth-btn mb-1 mr-15 butn butn-md text-light">
                              <span className="text slide-up">Sign In</span>
                                  <span className="text slide-down">Sign In</span>
                              </Button>
                              <Button onClick={authClickUp} variant="primary" className="auth-btn mb-1 ml-15 gr-purple-bg butn butn-md text-light">
                              <span className="text slide-up">Sign Up</span>
                                  <span className="text slide-down">Sign Up</span>
                              </Button>
                               
                              </div>
            </>
          )
          :
          null
      }

      <div className="setone setwo">
        <div className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer" ref={navigationNextRef}>
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer" ref={navigationPrevRef}>
          <i className="fas fa-chevron-left"></i>
        </div>
        {/* <div className="swiper-pagination top botm" ref={paginationRef}></div> */}

        {/* <div className="social-icon">
          <a href="#0"><i className="fab fa-facebook-f"></i></a>
          <a href="#0" className="ms-1"><i className="fab fa-twitter"></i></a>
          <a href="#0" className="ms-1"><i className="fab fa-behance"></i></a>
          <a href="#0" className="ms-1"><i className="fab fa-pinterest-p"></i></a>
        </div> */}
      </div>

      <div className="curve-buttom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="100">
          <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0" fill="#fafafb"></path>
        </svg>
      </div>
    </header>
  );
}

export default Header