import React from "react";


//layoutpages
import Default from "../layouts/dashboard/default";
import Intro from "../views/dashboard/auth/intro";

import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";



export const IndexRouters = [
  {
    path: "/home",
    element: <Intro />
  },
  {
    path: "/",
    element: <Default />,
    children: [...DefaultRouter, ...Layout1Router],
  },
];
